import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { access } from '@/configs/permissions'

Vue.use(Router)

const routes = [
  {
    path: '*',
    name: 'all',
    redirect: '/404'
  },
  {
    path: '/404',
    name: 'not-found',
    meta: {
      public: true,
      access: 'public'
    },
    component: () => import('../views/Page404')
  },
  {
    path: '/server-error',
    name: 'serverError',
    meta: {
      public: true,
      access: 'public'
    },
    component: () => import('../views/ServerError')
  },
  {
    path: '/',
    name: 'main',
    meta: {
      access: 'admin'
    },
    component: () => import('../views/Licenses/Licenses.vue')
  },
  {
    path: '/accounts',
    name: 'accounts',
    meta: {
      access: 'admin'
    },
    component: () => import('../views/Accounts/Accounts.vue')
  },
  {
    path: '/accounts/:id',
    name: 'accounts-info',
    meta: {
      access: 'admin'
    },
    component: () => import('@/views/Accounts/Info.vue')
  },
  {
    path: '/changelog',
    name: 'changelog',
    meta: {
      access: 'admin'
    },
    component: () => import('@/views/Changelog/Changelog.vue')
  },
  {
    path: '/changelog/:id',
    name: 'changelog-info',
    meta: {
      access: 'admin'
    },
    component: () => import('@/views/Changelog/Info.vue')
  },
  {
    path: '/article',
    name: 'article',
    meta: {
      access: 'admin'
    },
    component: () => import('@/views/Article/Article.vue')
  },
  {
    path: '/article/:id',
    name: 'article-info',
    meta: {
      access: 'admin'
    },
    component: () => import('@/views/Article/Info.vue')
  },
  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   meta: {
  //     access: 'public'
  //   },
  //   component: () => import('../views/Dashboard/Dashboard'),
  // },
  {
    path: '/login',
    name: 'login',
    meta: {
      public: true
    },
    component: () => import('../views/Login')
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (from.name === to.name && JSON.stringify(from.query) !== JSON.stringify(to.query)) {
    next()
  } else {
    const isAuth = Vue.prototype.$auth.isAuth()
    const profile = store.state.profile.user

    if (isAuth) {
      if (Object.keys(profile).length) {

        if (to.name === 'not-found' || to.name === 'server-error') next()
        else if (to.meta.public) next('/')
        else if (access(to.meta.access)) next()
        else {
          store.dispatch('resetUserData')
          next('/login')
        }
      } else {
        store.commit('SET_DATA', { store: 'app', state: 'isLoad', data: true })

        store.dispatch('getProfile')
          .then(() => {
            // next()
            if (to.name === 'not-found' || to.name === 'server-error') next()
            else if (to.meta.public) next('/')
            else access(to.meta.access) ? next() : next('/404')
          })
          .finally(() => {
            store.commit('SET_DATA', { store: 'app', state: 'isLoad', data: false })
          })
      }

    } else {
      const oldPath = encodeURIComponent(window.location.pathname)
      store.commit('SET_DATA', { store: 'app', state: 'isLoad', data: false })
      to.name === 'login' || to.name === 'registration' ? next() : next('/login?path=' + oldPath)
    }
  }
})

export default router
