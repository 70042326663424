<template lang="pug">
  div.text-center
    h1 Error 404
    img(src="https://cdn.dribbble.com/users/2552663/screenshots/11049549/media/8047a63c81fca9f26ac8d904466a714d.jpg" alt="")
    h2 The page you are looking for does not exist!
    v-btn(
      to="/"
      color="blue"
    ).mt-10.white--text Go back home
</template>

<script>
export default {
  name: "Page404",
   data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>

.bg {
  height: 20vh;
  background-position: center;
  background-size: cover;
}
h1 {
  font-size: 50px;
}
h3 {
  font-size: 80px;
}
img {
  max-width: 40%;
}

</style>
