<template lang="pug">
  div.mb-6
    v-card-text.mb-4.pb-0.px-6
      v-tabs(
        v-model="mode"
        background-color="transparent"
        @change="resetPage"
      ).custom-tabs
        v-tab(key="payment").border-right
          | {{ $t('payment') }}
        v-tab(key="fee")
          | {{ $t('fee') }}
    Table(
      :table-prefix="modeItems[mode]"
      ref="table"
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :pageCount="pageCount"
      :changePage="changePage"
      :isLoad="isLoad"
      :noDataText="$t('NoAvailablePayments')"
      @on-download="onDownload"
    ).mb-0
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {
    Table: () => import('@/components/layout/Table.vue')
  },
  name: 'PaymentsTable',
  props: {
    profileId: {
      type: Number,
      default: 0
    },
    itemsPerPage: {
      type: Number,
      default: 9
    }
  },
  data () {
    return {
      mode: 0,
      modeItems: [
        'payment', 'fee'
      ],
      currentPage: [this.$route.query.payment_page || 1, this.$route.query.fee_page || 1],
      pageCount: 0,
      isLoad: true,
      items: []
    }
  },
  computed: {
    headers () {
      const mode = this.modeItems[this.mode]
      return [
        {
          text: this.$t('Date'),
          value: 'created_at',
          align: 'center',
          sortBy: `${ mode }_datetime`
        },
        {
          text: this.$t('Amount'),
          value: 'netto_amount',
          align: 'center',
          sortBy: 'gross_amount'
        },
        {
          text: this.$t('Description'),
          value: 'description',
          align: 'center'
        },
        {
          text: this.$t('Status'),
          value: 'status',
          sortable: false,
          align: 'center'
        },
        {
          text: this.$t('Download'),
          value: 'download',
          sortable: false,
          align: 'center'
        }
      ]
    }
  },
  mounted () {
    this.changePage()
  },
  methods: {
    ...mapActions(['getPayments', 'downloadPayment']),
    resetPage () {
      // this.currentPage = 1
      this.changePage()
    },
    async changePage () {
      const direction = this.mode ? -1 : 1
      const mode = this.modeItems[this.mode]
      this.isLoad = true
      const params = {
        id: this.profileId,
        page_size: this.itemsPerPage,
        page: this.$route.query[`${ mode }_page`] || this.currentPage[this.mode] || 1,
        mode: this.modeItems[this.mode]
      }

      if (this.$route.query[`${ mode }_ordering`])
        params.ordering = this.$route.query[`${ mode }_ordering`]
      const data = await this.getPayments(params)
      if (!data?.results) return
      this.items = data.results.map(item => ({
        ...item,
        download: '#',
        status: true,
        gross_amount: item.gross_amount * direction
      }))
      this.pageCount = Math.ceil(data.count / this.itemsPerPage)
      this.isLoad = false
      // this.itemsPerPage = data.results.length

    },

    async onDownload ({ id }) {
      const account = this.profileId
      const mode = this.modeItems[this.mode]
      const data = await this.downloadPayment({ id, account, mode })
      const fileURL = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
      const fileLink = document.createElement('a')

      fileLink.setAttribute('target', '_blank')
      fileLink.href = fileURL
      document.body.appendChild(fileLink)

      fileLink.click()
      fileLink.remove()
    }
  }
}
</script>

