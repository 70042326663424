<template lang="pug">
  div.d-flex.justify-start.w-full.phoneNumberField(:class="mainClass")
    div.gray--text.text-left.text-truncate.flex-shrink-0(:class="titleClass") {{ $t(label) }} *:
    div.w-full
      VuePhoneNumberInput(
        v-model="phoneNumber"
        required
        :dark="true"
        :translations="$t('countryCodes')"
        no-example
        :ignored-countries="['RU']"
        :preferred-countries="preferredCountries"
        :fetch-country="isfetchCountry"
        :default-country-code="defaultCountryCode"
        @update="onInput"
        @phone-number-blur="onBlur"
      )
      v-slide-y-transition(:leave-absolute="true")
        div(v-if="errorMessages.length || errorMsg" key="error").v-messages.error--text.mt-1.px-3.mb-2 {{ errorMsg || errorMessages[0] }}
        div(v-else).v-messages.error--text.mt-1.px-3.mb-2

</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  props: {
    profile: {
      type: Object,
      default: () => ({})
    },
    label: { type: String, default: 'phoneNumber' },
    defaultValue: { type: String, default: '' },
    isDefaultCountryCode: { type: Boolean, default: false },
    errorMessages: { type: Array, default: () => [] },
    direction: {
      type: String,
      default: 'horizontal'
    }
  },
  components: {
    VuePhoneNumberInput
  },
  data () {
    return {
      isNotEmpty: false,
      isValid: true,
      phoneNumber: null,
      formattedNumber: null,
      errorMsg: ''
    }
  },
  computed: {
    preferredCountries () {
      const result = ['US', 'UA', 'KZ', 'HR', 'KG']
      const userCountry = this.profile?.address?.country?.code
      if (userCountry && !result.includes(userCountry))
        result.unshift(this.profile?.address?.country?.code)
      return result
    },
    isfetchCountry () {
      if (!this.profile?.address) return false
      return !this.defaultCountryCode
    },
    defaultCountryCode () {
      return (this.isNotEmpty || this.isDefaultCountryCode) && this.profile?.address?.country?.code ? this.profile.address.country.code : null
    },
    mainClass () {
      if (this.direction === 'vertical') return 'flex-column input-vertical'
      return 'input-horizontal justify-space-between'
    },
    titleClass () {
      if (this.direction === 'vertical') return 'input-vertical-title font16'
      return 'pt-3 font16 input-horizontal-title'
    }
  },
  watch: {
    defaultValue () {
      // console.log('defaultValue', this.defaultValue)
      this.fieldsAutocomplete()
    }
  },
  mounted () {
    // this.phoneNumber = '+6563323659'
    this.fieldsAutocomplete()
  },
  methods: {
    checkError () {
      if (!this.isValid) this.errorMsg = this.$t('notify.validation.phoneNumber')
      else this.errorMsg = ''
    },
    onBlur () {
      this.checkError()
    },
    onInput ({ formattedNumber, isValid }) {
      this.isValid = isValid
      this.formattedNumber = formattedNumber
      this.errorMsg = ''
      this.$emit('input', this.formattedNumber)
    },
    fieldsAutocomplete () {
      this.isNotEmpty = this.isNotEmpty || !!this.defaultValue
      this.phoneNumber = this.defaultValue
    }
  }
}
</script>

<style lang="sass" scoped>
.phoneNumberField.sign__field
  .input-horizontal-title
    width: 33%
.phoneNumberField
  .v-messages
    line-height: 12px
    height: 12px
</style>
