<template lang="pug">
  Table(
    ref="table"
    :headers="headers"
    :items="items"
    :items-per-page="itemsPerPage"
    :pageCount="pageCount"
    :changePage="changePage"
    :isLoad="isLoad"
    :noDataText="$t('NoAvailableData')"
    :btnLinkFunction="btnLinkFunction"
  )
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {
    Table: () => import('@/components/layout/Table.vue')
  },
  name: 'TableLicense',
  data () {
    return {
      itemsPerPage: 20,
      currentPage: this.$route.query.page || 1,
      pageCount: 0,
      isLoad: true,
      items: [],
      headers: [
        {
          text: this.$t('Date'),
          value: 'released_at',
          align: 'center'
        },
        {
          text: this.$t('logName'),
          value: 'version',
          align: 'center'
        },
        {
          text: this.$t('Amount'),
          value: 'changelogs_count',
          align: 'center'
        },
        {
          text: this.$t('view'),
          value: 'itemLink',
          sortable: false,
          align: 'center'
        }
      ]
    }
  },
  mounted () {
    this.changePage()
  },
  methods: {
    ...mapActions(['getReleases']),
    btnLinkFunction (item) {
      return { name: 'changelog-info', params: { id: item.id } }
    },
    async changePage () {
      this.isLoad = true
      const data = await this.getReleases({
        ...this.$route.query,
        page_size: this.itemsPerPage,
        page: this.$route.query.page || this.currentPage || 1
      })
      if (!data?.results) return
      this.items = data.results
      this.pageCount = Math.ceil(data.count / this.itemsPerPage)
      this.isLoad = false
    }
  }
}
</script>

