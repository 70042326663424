export default {
  notify: {
    articleDeleted: 'Article deleted',
    articleUpdated: 'Article updated',
    articleCreated: 'Article created',
    imgSizeTooBig: 'Image size is too big',
    userDeleted: 'User deleted',
    downloadSuccess: 'Download successful',
    removeLogSuccessful: 'Remove log successful',
    updateLogSuccessful: 'Update log successful',
    passwordReset: 'Password reset email sent',
    nextMonthPaid: 'Next month paid',
    thankForPayment: 'Thanks for the payment',
    requiredField: 'This field is required',
    noData: 'Data not found',
    onlyLatinNumeric: 'Only latin letters and numbers are allowed',
    addedOLT: 'OLT has been successfully added',
    info: 'Info',
    warning: 'Warning',
    createdSuccess: 'Created successful',
    success: 'Success',
    error: 'Error',
    notExist: 'Does not exist (400)',
    serverError: 'Server error (500)',
    required: 'The field is required',
    requestTimeout: 'Timeout request error (504)',
    invalidLength: 'Invalid length detected',
    unregisteredOnuFailed: 'Connection lost! Search for an unregistered ONU is not possible',
    userExist: 'User with this email already exist',
    websiteError: 'Website incorrect',
    userRegistered: 'Registration was successful',
    matchingPasswords: 'Passwords does not match',
    matchingLicenseName: 'license name does not match',
    userUpdated: 'User updated successful',
    passwordUpdated: 'Passwords changed successful',
    licenseUpdated: 'License updated successful',
    licenseActivated: 'License activated successful',
    licenseDeactivated: 'License deactivated successful',
    validation: {
      maxLength: 'Maximum field length reached',
      minLength: 'Min length is {min}',
      minDateValue: 'Date must be after than now',
      maxValue: 'Ensure this value is less than or equal to {max}',
      decimal: 'This field must be a decimal number',
      minAmount: 'Min amount cant be less 0.02',
      phoneNumber: 'Enter a valid phone number',
      email: 'Enter a valid email',
      required: 'This field is required',
      alphaNum: 'Username must be valid',
      alpha: 'This string must contain letters',
      regexAlphaNumeric: 'Only latin letters, numbers or dot'
    },
    new_password: {
      Newandoldpasswordscantbethesame: 'New and old passwords cant be the same',
      Thispasswordisentirelynumeric: 'New password is entirely numeric',
      Thispasswordistoocommon: 'New password is too common',
      Incorrectpassword: 'Incorrect new password',
      ThispasswordistooshortItmustcontainatleast8characters: 'New password is too short. It must contain at least 8 characters'
    },
    old_password: {
      Incorrectpassword: 'Incorrect old password'
    },
    serverErrors: {
      Incorrectemailorpassword: 'Incorrect username or password. Please, check your credentials'
    }
  },
  btn: {
    saveArticle: 'Save article',
    deleteUser: 'Delete user',
    remove: 'Remove',
    resetPassword: 'Reset password',
    sign: 'Sign in',
    detail: 'Detail',
    save: 'Save',
    add: 'Add',
    addOlt: 'Add OLT',
    open: 'Open',
    delete: 'Delete',
    cancel: 'Cancel',
    setCourse: 'Set course',
    close: 'Close',
    readKey: 'Read key',
    signDocument: 'Sign',
    search: 'Search',
    reset: 'Reset',
    filterONU: 'Filter ONU',
    filterOLT: 'Filter OLT',
    closeFilter: 'Close filter',
    create: 'Create',
    configType: 'Config type',
    addPreset: 'Add preset',
    addMacros: 'Add macros',
    clear: 'Clear',
    register: 'Register',
    change: 'Change',
    apply: 'Apply',
    back: 'Back',
    addTypesOnu: 'Add types ONU',
    restart: 'Restart',
    reboot: 'Reboot',
    activate: 'Activate',
    deactivate: 'Deactivate',
    find: 'Find',
    addUser: 'Add user',
    addGroup: 'Add group',
    choose: 'Choose',
    edit: 'Edit',
    refresh: 'Refresh',
    addSwitch: 'Add switch',
    get: 'Get',
    sync: 'Sync',
    pay: 'Pay',
    SendMessage: 'Send message',
    Deactivate: 'Deactivate'
  },
  lang: {
    ukInstrumental: 'in ukrainian',
    enInstrumental: 'in english',
    ruInstrumental: 'in russian',
    uk: 'Ukrainian',
    en: 'English',
    ru: 'Russian'
  },
  units: {
    dollar: '$',
    celsius: '°С',
    percent: '%'
  },
  statuses: {
    loss: 'Loss',
    notExist: 'Does not exist',
    synchronize: 'Synchronize',
    online: 'Online',
    powerOff: 'Power off',
    deleted: 'Deleted',
    offline: 'Offline',
    unregistered: 'Unregistered',
    def: 'Default'
  },
  userType: 'User type',
  username: 'Username',
  password: 'Password',
  old_password: 'Old password',
  new_password: 'New password',
  confirmNewPassword: 'Confirm new password',
  signIn: 'Sign in',
  signUp: 'Sign up',
  logout: 'Logout',
  dontHaveAcc: 'Don\'t have an account yet?',
  name: 'Name',
  email: 'Email',
  companyName: 'Company name',
  haveAcc: 'Already have an account?',
  surname: 'Surname',
  phoneNumber: 'Phone',
  message: 'Message',
  mainPage: 'Main page',
  mainPageSubTitle: 'Server basic info',
  balance: 'Balance',
  tariff: 'Tariff',
  monthlyPayment: 'Withdrawals every month',
  paymentDateNext: 'Next payment date',
  accountStatus: 'Account status',
  tariffs: 'Tariffs',
  tariffsInfo: 'Tariffs info',
  tariffsSubTitle: 'List of tariffs',
  costPerOnu: 'Cost per ONU',
  costPerMonth: 'Cost per month',
  onuInTariff: 'ONU in tariff',
  status: 'Status',
  usedOnu: 'Number of used ONU',
  // availableOnu: 'Number of non-connected ONU',
  website: 'Website',
  addServer: 'Adding new server instance',
  editServer: 'Editing server instance',
  DeactivateLicense: 'Deactivate license',
  ActivateLicense: 'Activate license',
  DeactivateLicenseDescription: 'To make this action, please, type “{name}” and press Enter',
  '@': '@',
  https: 'https://',
  confirmPassword: 'Confirm password',
  noNameServer: 'No Name Server',
  upTo: 'Up to ',
  payment: 'payment',
  paymentPageSubTitle: 'Detailed information about payments',
  paymentPage: 'Payment',
  PaymentDetails: 'Payment details',
  PreferredPaymentAmount: 'Preferred payment amount',
  payFor: 'Pay for',
  payPeriod: {
    'l1m': 'less than a month',
    '1m': '1 month',
    '2m': '2 month',
    '3m': '3 month',
    '4m': '4 month',
    '5m': '5 month',
    '6m': '6 month',
    '7m': '7 month',
    '8m': '8 month',
    '9m': '9 month',
    '10m': '10 month',
    '11m': '11 month',
    '12m': '12 month',
    'm12m': 'more than a year',
    '24m': '2 year',
    'm24m': 'more than a 2 year'
  },
  Date: 'Date',
  Amount: 'Amount',
  Description: 'Description',
  Download: 'Download',
  Status: 'Status',
  preview: 'Previous',
  next: 'Next',
  Payment: 'Payment',
  NextPayment: 'Next Payment',
  MakePayment: 'Make a payment',
  ServerLink: 'Server link',
  PersonalInformation: 'Personal Information',
  editProfile: 'Edit user',
  changePassword: 'Change password',
  ContactUs: 'Contact us',
  AskHereOurSalesDepartmentForATrial: 'Ask here our sales department for a trial',
  GetInTouch: 'Get in touch',
  GetInTouchDescription: 'Want to make sure of the effectiveness of EasyPON service? Leave a request and get free trial for a whole month! You will have access to all the advantages of our platform, including an unlimited number of ONU.',
  extraMoneyFor: 'extra money for',
  SpecialOffer: 'Special offer',
  YouAgreeToOurFriendlyPrivacyPolicy: 'You agree to our friendly privacy policy.',
  AskForFullTrial: 'Ask for full trial',
  LicenseName: 'License name',
  Deactivate: 'Deactivate',
  Activate: 'Activate',
  DownloadEasyPON: 'Download EasyPON',
  PleaseWait: 'Please wait',
  Documentation: 'Documentation',
  NoAvailablePayments: 'No available payments',
  SelectServer: 'Select server',
  PaymentStatus: 'Payment status',
  Paid: 'Paid',
  NeedToPay: 'Need to pay',
  Balance: 'Balance',
  CurlForDownload: 'Curl for download',
  WgetForDownload: 'Wget for download',
  copy: 'Copy',
  copied: 'Copied',
  PaymentMethod: 'Payment method',
  downloadEp: 'Download EasyPON',
  scriptForDebian: 'Script for Debian',
  dockerCompose: 'Script for Docker',
  countUsedOnu: 'ONU amount',
  serverStatus: 'Server status',
  banned: 'Banned',
  active: 'Active',
  inactive: 'Inactive',
  licenses: 'Licenses',
  admin: 'Admin',
  users: 'Users',
  user: 'User',
  clientName: 'Client name',
  location: 'Location',
  view: 'View',
  AddPayment: 'Add payment',
  amount: 'Amount',
  editDiscount: 'Edit discount',
  discountAmount: 'Discount amount',
  discountActiveTo: 'Discount active to',
  editBillingAddress: 'Edit billing address',
  country: 'Country',
  city: 'City',
  region: 'Region',
  street: 'Street',
  zip_code: 'Zip code',
  paymentInformation: 'Payment information',
  billingInformation: 'Billing information',
  unknown: 'Unknown',
  fee: 'Fee',
  discount: 'Discount',
  onuAmount: 'ONU amount',
  comment: 'Comment',
  easyponVersion: 'EasyPON version',
  order: 'Order',
  youReallyWontToResetPassword: 'Do you really want to reset password?',
  resetPassword: 'Reset password',
  easyponVersionLatest: 'EasyPON version latest',
  discountActivateTo: 'Discount active to',
  infinity: 'Infinity',
  percent: '%',
  makeMeAdmin: 'Make user admin',
  check: 'Check',
  description: 'Description',
  cancel: 'Cancel',
  save: 'Save',
  version: 'Version',
  changelogsCount: 'Changelogs count',
  releasedAt: 'Released at',
  changelog: 'Changelog',
  changes: 'changes',
  createLog: 'Add new change',
  is_active: 'Is active',
  is_active_false: 'Is not active',
  removeLog: 'Remove log',
  removeLogDescription: 'Do you really want to remove log?',
  addNewChange: 'Add new change',
  noData: 'No data',
  logName: 'Log name',
  updatedAt: 'Updated at',
  NoAvailableData: 'No available data',
  typesUser: {
    All: 'All',
    Admin: 'Admin',
    Client: 'Client'
  },
  serverStatuses: {
    All: 'All',
    Offline: 'Offline',
    Online: 'Online',
    Unknown: 'Unknown'
  },
  typesPublished: {
    all: 'All',
    true: 'Published',
    false: 'Unpublished'
  },
  typeUser: 'Type user',
  search: 'Search',
  licence: 'Licence',
  author: 'Author',
  All: 'All',
  deleteUser: 'Delete user',
  deleteUserDescription: 'Do you really want to delete {user}?',
  getRcVersions: 'Get RC versions',
  getRcVersionsFalse: 'Don\'t get RC versions',
  isBanned: 'Get RC versions',
  isBannedFalse: 'Don\'t get RC versions',
  diactivateUser: 'Deactivate user',
  Active: 'Active',
  Banned: 'Banned',
  is_banned: 'Is banned',
  is_bannedFalse: 'Is not banned',
  licenseInstalled: 'License is installed',
  licenseInstalledFalse: 'License is not installed',
  countryCodes: {
    countrySelectorLabel: 'Country code',
    countrySelectorError: 'Choose country',
    phoneNumberLabel: 'Phone',
    example: 'Example :'
  },
  isInstalled: 'Restrict user of reinstalling the licence',
  isInstalledFalse: 'Allow user to reinstall its licence',
  installDebian: 'Install EasyPON in Debian',
  installDocker: 'Install EasyPON in Docker',
  upgradeDebian: 'Upgrade EasyPON in Debian',
  upgradeDocker: 'Upgrade EasyPON in Docker',
  repairDebian: 'Repair EasyPON in Debian',
  repairDocker: 'Repair EasyPON in Docker',
  accessingDebian: 'Accessing EasyPON in Debian',
  accessingText: 'You will be prompted to log in with your credentials, which you received in the terminal during installation, or which can be found in',
  slug: 'Slug',
  title: 'Title',
  content: 'Content',
  isPublished: 'Is published',
  en: 'English',
  hr: 'Croatian',
  uk: 'Ukrainian',
  addImageTitle: 'Add image title',
  removeImageTitle: 'Remove image title',
  tags: 'Tags',
  editArticle: 'Edit article',
  editArticleDescription: 'Do you really want to edit article?',
  language: 'Language',
  articles: 'Articles',
  createArticle: 'Create article',
  seoTags: 'SEO tags',
  seoTagsDescription: 'It is a short description of the article, which is displayed in the search engine results',
  tag: 'Tag',
  publishAfterSaving: 'Publish after saving',
  article: 'Article',
  backToArticles: 'Back to articles',
  published: 'Published',
  unpublished: 'Unpublished',
  all: 'All',
  deleteArticle: 'Delete article',
  deleteArticleDescription: 'Do you really want to delete article?',
  licenseInstalledWarning: 'This action is usually used for letting the customer install its licence the second time in case of moving EasyPON to another server, or if the QA of EasyPON needs to test licence on multiple installations. When the toggle is ON - the action is automatically restricted. You can allow it for the users just for a special case.'
}

