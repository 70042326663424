<template lang="pug">
  MainPage(isShowLogout, :title="$t('user')")
    v-row
      v-col.col-8
        PaymentCash(
          :account="account"
          @onSend="onPaymensUpdate"
        )
        PaymentTable( v-if="account.email" ref="paymentTable" :profileId="account.id" :items-per-page="3")
        LicenseList(v-if="account.email" :author="account.email")
      v-col.col-4
        ProfileInfo(:profile="account" @onUpdate="getAccount").mb-6
        PaymentInfo(:profile="account" @onUpdate="getAccount").mb-6
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'Licenses',
  components: {
    LicenseList: () => import('@/components/Blocks/Licenses/List.vue'),
    PaymentInfo: () => import('@/components/Blocks/Payment/Info.vue'),
    ProfileInfo: () => import('@/components/Blocks/Profile.vue'),
    PaymentTable: () => import('@/components/Blocks/Payment/Table.vue'),
    PaymentCash: () => import('@/components/Blocks/Payment/Cash.vue'),
    MainPage: () => import('@/components/layout/MainPage.vue')
  },
  data () {
    return {
      account: {}
    }
  },
  mounted () {
    this.getAccount()
  },
  methods: {
    onPaymensUpdate () {
      this.$refs.paymentTable.changePage()
      this.getAccount()
    },
    ...mapActions(['getAdminAccount']),
    async getAccount (data) {
      const id = this.$route.params.id
      this.account = data || await this.getAdminAccount({ id })
    }
  }
}
</script>
