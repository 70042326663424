<template lang="pug">
  include ../../plugins/pug/template
  div
    v-card
      v-card-title.px-6.pt-6
        div.d-flex.flex-row.justify-lg-space-between.align-center.w-full.font24
          span.text-ellipsis.mr-2 {{ profile.company_name }}
          div.d-fle.flex-row.align-center
            template(v-if="selfProfileId !== profile.id")
              v-btn(
                icon
                @click="isViewFormRemove = !isViewFormRemove"
              )
                img(:src="DeleteDark" alt="delete")
              v-btn(
                icon
                @click="showFormDeactivateUser"
              )
                img(:src="SlashDark" alt="slash")
            v-btn(icon @click="showFormProfileEdit")
              img(:src="EditBlockDark" alt="edit")

      v-card-text.mt-3.px-6.pb-5
        +info-row({
          model: '$t(status)',
          label: 'accountStatus',
          class: 'success--text font24'
        })(v-if="status === 'Active'")
        +info-row({ model: '$t(status)', label: 'accountStatus', class: 'error--text font24' })(v-else)
        v-divider.mb-4.mt-4
        +info-row({ model: 'profile.first_name', label: 'name' })(v-if="profile.first_name").mb-3
        +info-row({ model: 'profile.last_name', label: 'surname' })(v-if="profile.last_name").mb-3
        +info-row({ model: 'profile.type_user', label: 'userType' })(v-if="profile.type_user").mb-3
        +info-row({ model: 'profile.email', label: 'email' }).mb-3
        +info-row({ model: 'profile.website', label: 'website' })(v-if="profile.website").mb-3
        +info-row({ model: 'profile.phone_number', label: 'phoneNumber' })(v-if="profile.phone_number").mb-3
        +info-row({
          model: '$t("resetPassword")',
          label: 'password',
          class: 'underline cursor-pointer color-gradient',
          click: 'isViewFormResetPassword = !isViewFormResetPassword'
        }).mb-0
    v-card(v-if="profile.comment").mt-5
      v-card-title.font18.px-6.pt-6.mb-0.pb-0.d-flex.flex-row.justify-lg-space-between.align-center
        span {{ $t('comment') }}
        v-btn(
          icon
          @click="showFormDeactivateUser"
        )
          img(:src="EditBlockDark" alt="edit")
      v-card-text.mt-3.px-6.pb-5
        | {{ profile.comment }}
    v-dialog(v-model="isViewFormResetPassword" width="400" )
      FormResetPassword(
        :email="profile.email"
        :title="$t('resetPassword')"
        :isLoad="isLoad"
        @close="closeForm"
      )
    v-dialog(v-model="isViewFormDeactivateUser" width="400" )
      FormDeactivateUser(
        ref="formDeactivateUser"
        :isLoad="isLoad"
        @send="editProfile"
        @close="closeForm"
      )
    v-dialog(v-model="isViewFormRemove" width="400" )
      FormRemoveUser(
        :user="profile"
        :title="$t('deleteUser')"
        @close="closeForm"
      )
    v-dialog(v-model="isViewFormEditProfile" width="600" )
      FormProfileEdit(
        ref="EditProfile"
        :title="$t('editProfile')"
        :isLoad="isLoad"
        :profile="profile"
        @send="editProfile"
        @close="closeForm"
      )
</template>

<script>
import { mapActions, mapState } from 'vuex'
import EditBlockDark from '@/assets/icons/edit-block-dark.svg'
import DeleteDark from '@/assets/icons/delete-dark.svg'
import SlashDark from '@/assets/icons/slash-dark.svg'

export default {
  name: 'Profile',
  components: {
    FormRemoveUser: () => import('@/components/Forms/RemoveUser.vue'),
    FormDeactivateUser: () => import('@/components/Forms/DeactivateUser.vue'),
    FormProfileEdit: () => import('@/components/Forms/ProfileEdit.vue'),
    FormResetPassword: () => import('@/components/Forms/ResetPassword.vue')
  },
  props: {
    profile: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      selfProfileId: state => state.profile.user?.id
    }),
    status () {
      if (this.profile.is_banned) return 'Banned'
      return 'Active'
    }
  },
  data () {
    return {
      DeleteDark,
      SlashDark,
      isViewFormDeactivateUser: false,
      isViewFormRemove: false,
      isLoad: false,
      isViewFormEditProfile: false,
      isViewFormResetPassword: false,
      EditBlockDark
    }
  },
  methods: {
    ...mapActions(['patchUser']),
    showFormDeactivateUser () {
      const { is_banned, comment } = this.profile
      this.isViewFormDeactivateUser = !this.isViewFormDeactivateUser
      this.$nextTick(() => {
        this.$refs.formDeactivateUser.editForm({ is_banned, comment })
      })
    },
    showFormProfileEdit () {
      const {
        first_name,
        last_name,
        company_name,
        email,
        website,
        phone_number,
        type_user
      } = this.profile
      this.isViewFormEditProfile = !this.isViewFormEditProfile
      this.$nextTick(() => {
        this.$refs.EditProfile.editBody({
          first_name,
          last_name,
          company_name,
          email,
          website,
          phone_number,
          type_user
        })
      })
    },
    async editProfile (originBody) {
      if (this.isLoad) return
      const body = {}
      for (let key in originBody) {
        if (originBody[key] !== this.profile[key]) body[key] = originBody[key]
      }

      if (!Object.keys(body).length) return
      this.isLoad = true

      const userData = await this.patchUser({ body, id: this.profile.id })
      if (userData) {
        this.closeForm()
        this.$emit('onUpdate', userData)
      }
      this.isLoad = false
    },
    closeForm () {
      this.isLoad = false
      this.isViewFormEditProfile = false
      this.isViewFormResetPassword = false
      this.isViewFormRemove = false
      this.isViewFormDeactivateUser = false
      setTimeout(() => {
        this.$refs.formDeactivateUser?.clearForm()
        this.$refs.EditProfile?.clearForm()
      }, 300)
    }
  }
}
</script>
