<template lang="pug">
  div
    v-data-table(
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :loading="isLoad"
      :options.sync="options"
      :serverItemsLength="serverItemsLength"
      loading-text="Loading..."
      no-results-text="Data not found"
      no-data-text="No data"
      hide-default-footer
      :mobile-breakpoint="mobileBreakpoint"
      :class="customClass"
      @click:row="handleRowClick"
      @update:sort-desc="updatePage(1, options)"
    )
      template(#item.description="{ item }")
        v-tooltip(right)
          template(v-slot:activator="{ on, attrs }")
            div.description.cursor-pointer(v-bind="attrs", v-on="on") {{ item.description }}
          template(v-slot:default)
            span {{ item.description }}
            span(v-if="item?.fondy_payment?.order_id")
              br
              | {{ $t('order') }}: {{ item?.fondy_payment?.order_id }}

      template(#item.index="{ index }")
        span {{ index + 1 + items.length * (currentPage - 1) }}

      template(#item.fullName="{ item }")
        span {{ item.last_name }} {{ item.first_name }}

      template(#item.created_at="{ item }")
        span {{ $moment.tz(item.created_at, 'Europe/Kiev').format('DD.MM.YYYY') }}

      template(#item.released_at="{ item }")
        span {{ $moment.tz(item.released_at, 'Europe/Kiev').format('DD.MM.YYYY') }}

      template(#item.add="{ item }")
        v-btn(icon @click="$emit('add', item)")
          img(:src="AddDark")

      template(#item.delete="{ item }")
        v-btn(icon @click="$emit('delete', item)")
          img(:src="DeletePrimary")

      template(#item.edit="{ item }")
        v-btn(icon @click="$emit('edit', item)")
          img(:src="EditDark")

      template(#item.download="{ item }")
        v-icon(@click="$emit('on-download', item)").download-link.gray--text
          | mdi-tray-arrow-down

      template(#item.balance="{ item }")
        span {{ $number(item.balance) }}{{ $t('units.dollar') }}

      template(#item.next_payment="{ item }")
        span {{ $number(item.next_payment) }}{{ $t('units.dollar') }}

      template(#item.server_status="{ item }")
        v-chip(
          :class="[{'status--complete ': item.server_status === 'Online'}, {'status--error ': item.server_status === 'Offline'}, {'status--unknown ': item.server_status === 'Unknown'}]"
          class="ma-2 status"
          text-color="white")
          v-icon.mr-1.ml-0
            | mdi-checkbox-blank-circle
          | {{ item.server_status }}

      template(#item.itemLink="{ item }")
        v-btn(v-if="btnLinkFunction(item)" :to="btnLinkFunction(item)" exact icon)
          img(:src="RowRightDark")

      template(#item.is_banned="{ item }")
        v-chip(
          :class="[{'status--complete ': !item.is_banned && item.is_active }, {'status--error ':  item.is_banned || !item.is_active}]"
          class="ma-2 status"
          text-color="white")
          v-icon.mr-1.ml-0
            | mdi-checkbox-blank-circle
          | {{ item.is_banned ? $t('banned') : item.is_active ? $t('active') : $t('inactive') }}

      template(#item.changelogs_count="{ item }")
        v-chip(
          :class="item.is_active ?'status--complete ' : 'status--error '"
          class="ma-2 status"
          text-color="white")
          v-icon.mr-1.ml-0
            | mdi-checkbox-blank-circle
          | {{ item.changelogs_count }} {{ $t('changes') }}
      template(#item.netto_amount="{ item }")
        div.currency-amount.flex-column.align-center.justify-center.d-flex
          div
            | {{ item.gross_amount > 0 ? '+' : '' }}{{ $number(item.gross_amount) }}{{ $t('units.dollar') }}
          div.discount-wrapper(v-if="Math.trunc(item.cashback * 100 / item.netto_amount)")
            DiscountLabel(:percent="Math.trunc(item.cashback * 100 / item.netto_amount)")
      template(#item.status="{ item }")
        v-chip(
          class="ma-2 status--complete status"
          text-color="white")
          v-icon.mr-1.ml-0
            | mdi-checkbox-blank-circle
          | Completed
    v-pagination(
      v-if="pageCount >= 2"
      v-model="currentPage"
      :length="pageCount"
      :total-visible="7"
      :previous-aria-label="$t('preview')"
      :next-aria-label="$t('next')"
      aria-label='table-pagination'
      class="table-pagination"
    ).mb-5
</template>

<script>

import AddDark from '@/assets/icons/add-dark.svg'
import DeletePrimary from '@/assets/icons/delete-primary.svg'
import EditDark from '@/assets/icons/edit-dark.svg'
import RowRightDark from '@/assets/icons/row-right-dark.svg'
import { getSortingParam } from '@/mixins/main'

export default {
  components: {
    DiscountLabel: () => import('@/components/Blocks/Payment/DiscountLabel.vue')
  },
  props: {
    mobileBreakpoint: {
      type: Number,
      default: 960
    },
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    itemsPerPage: {
      type: Number,
      default: -1
    },
    pageCount: {
      type: Number,
      default: 0
    },
    serverItemsLength: {
      type: Number,
      default: -1
    },
    btnLink: {
      type: Object,
      default: null
    },
    detailedRoute: {
      type: String,
      default: null
    },
    changePage: {
      type: Function,
      default: null
    },
    tablePrefix: {
      type: String,
      default: ''
    },
    isLoad: {
      type: Boolean,
      default: false
    },
    actionIcon: {
      type: String,
      default: null
    },
    actionRowClick: {
      type: Function,
      default: null
    },
    btnLinkFunction: {
      type: Function,
      default: () => ({ name: 'main' })
    }
  },
  data () {
    const currentPage = Number(this.$route.query[`${ this.tablePrefix ? `${ this.tablePrefix }_` : '' }page`]) || 1

    const options = {
      sortBy: [],
      sortDesc: []
    }
    if (this.$route.query.ordering) {
      const order = this.$route.query.ordering
      const direction = order[0] !== '-'
      const item = direction ? order.replace('-', '') : order
      options.sortBy = [item]
      options.sortDesc = [direction]
    }
    return {
      currentPage,
      AddDark,
      DeletePrimary,
      EditDark,
      RowRightDark,
      options
    }
  },
  computed: {
    routePrefix () {
      return this.tablePrefix ? `${ this.tablePrefix }_` : ''
    },
    customClass () {
      const lastValue = this.headers.slice(-1)[0].value
      return ['itemLink', 'onuList'].includes(lastValue) ? ['table-with-custom-redirect-btn'] : []
    }
  },
  watch: {
    tablePrefix (val) {
      this.currentPage = Number(this.$route.query[`${ val ? `${ val }_` : '' }page`]) || 1
    },
    '$route.query' (next, prev) {
      const pageKey = `${ this.routePrefix }page`
      if (next[pageKey] && next[pageKey] !== prev[pageKey]) {
        const page = Number(next[pageKey])
        if (page !== Number(this.currentPage)) this.currentPage = page
      }
    },
    currentPage (val) {
      this.updatePage(val)
    }
  },
  methods: {
    handleRowClick (e) {
      if (typeof (this.actionRowClick) === 'function') this.actionRowClick(e)
    },
    updatePage (val, options = undefined) {
      const query = Object.assign({}, this.$route.query)
      let ordering = getSortingParam(options)

      ordering?.forEach((_item, key) => {
        const direction = _item[0] === '-' ? '-' : ''
        const item = direction ? _item.replace('-', '') : _item
        ordering[key] = direction + (this.headers.find(target => target.value === item).sortBy || item)
      })

      this.currentPage = val
      query[`${ this.routePrefix }page`] = String(val)
      if (ordering) query[`${ this.routePrefix }ordering`] = ordering

      this.$router.replace({ query, hash: this.anchor }, () => {})
      if (this.changePage) this.changePage()
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/sass/table"

</style>
