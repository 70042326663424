<template lang="pug">
  include ../../plugins/pug/template
  MainPage(:title="$t('article')" :isShowLogout="false" :subtitle="articleData.slug")
    template(#button)
      +btn('primary-gradient','backToArticles')(
        large
        :to="{ name: 'article' }"
      )
    div.d-flex.flex-row.mb-8
      v-dialog(v-model="isViewFormDelete" width="600" )
        FormDeleteArticle(
          :isLoad="isLoad"
          @onDelete="onDeleteArticle"
          @onClose="isViewFormDelete = false"
        )
      div.w-50.pr-4
        v-card.mb-4.title-image-container.bg-blue
          input(
            ref="fileid"
            type="file"
            @change="onDownloaded"
            accept="image/*"
          ).hidden
          div.flex-row.d-flex.justify-center.align-center.pa-6
            div(v-if="isLoad").loader.flex-row.d-flex.justify-center.align-center
              v-progress-circular(
                :size="40"
                color="primary"
                indeterminate)
            div(v-if="!titleImg" @click="$refs.fileid.click()").flex-row.d-flex.justify-center.align-center.add-btn
              img(
                v-if="!titleImg"
                :src="AddWhite"
                alt="add"
              ).mr-2.btn-img
              span {{ $t('addImageTitle') }}
            template(v-else)
              div(@click="removeFile(titleImg)").remove-btn.flex-row.d-flex.justify-center.align-center
                img(
                  :src="DeleteRed"
                  alt="delete"
                ).mr-2.btn-img
                span {{ $t('removeImageTitle') }}
              img(
                :src="titleImg"
                alt="title"
              ).title-image

      div.w-50.pl-4
        v-card.pa-4.bg-blue.ma-0
          v-card-title.d-flex.flex-row.justify-space-between.align-center.pb-2
            span.font30 {{ $t('seoTags')}}
          v-card-subtitle.mt-0.mb-6.gray--text.pb-0.pt-0.font16 {{ $t('seoTagsDescription') }}
          v-card-text.mb-0.pb-0
            div(v-for="(row, key) in html" :key="key").html-row.d-flex
              +field-label-validation({ model: 'row.tag', label: 'tag' }).mr-2
              +field-label-validation({ model: 'row.content', label: 'content' }).ml-2
              img(
                :src="DeleteRed"
                alt="delete"
                @click="onRemoveHtmlTag(key)"
              ).ml-3.cursor-pointer
          v-card-actions.d-flex.flex-row.align-center.ma-4.pa-0.justify-space-between
            +btn-general('btn.add', 'row-right-white.svg', 'primary-gradient')(
              large
              @click="onAddHtmlTag"
            ).mb-0

    v-card(tag="form" @submit.prevent="validation").pa-4.bg-blue
      v-card-title.d-flex.flex-row.justify-space-between.align-center.pb-2
        span.font30 {{ $t('editArticle')}}
      v-card-subtitle.mt-0.mb-6.gray--text.pb-0.pt-0.font16 {{ $t('editArticleDescription') }}
      v-card-text.mb-0.pb-0
        +field-label-validation({ model: 'body.title', label: 'title', validation: '["required"]' })(autofocus)
        +field-label-validation({ model: 'body.slug', label: 'slug', validation: '["required"]' })
        +select-label(
          'body.tags',
          'tagsList',
          'tags',
          'tag',
          'tag',
          '',
          '',
          true
        )(
          chips
          @change="onChangeTag"
          @update:search-input="onAddNewTag"
        )
        +select-label(
          'body.language',
          'languages',
          'language',
          'name',
          'value',
          '',
          '',
          false
        )(
          required,
        ).mr-1

        VueEditor(
          v-model="body.content"
          :editorToolbar="customToolbar"
          :placeholder="$t('startTyping')"
          useCustomImageHandler
          @image-added="onImageAdded"
          @image-removed="onImageDeleted"
        ).editor-wrapper.invert-color.big-size
        v-checkbox(
          v-model="body.is_published"
          :label="$t('publishAfterSaving')"
        ).mt-0
      v-card-actions.d-flex.flex-row.align-center.ma-4.pa-0.justify-space-between
        +btn-general('btn.delete', 'row-right-white.svg', 'grey-button')(
          :loading="isLoad"
          large
          @click="isViewFormDelete = true"
        ).mb-0
        +btn-general('btn.saveArticle', 'row-right-white.svg', 'primary-gradient')(
          type="submit"
          :loading="isLoad"
          large
        ).mb-0
    v-card(v-if="body.content").pa-4.bg-blue.mt-8
      div(v-html="body.content").article-content
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { VueEditor } from 'vue2-editor'
import AddWhite from '@/assets/icons/add-white.svg'
import DeleteRed from '@/assets/icons/delete-red.svg'

export default {
  name: 'ArticleInfo',
  components: {
    FormDeleteArticle: () => import('@/components/Forms/DeleteArticle.vue'),
    MainPage: () => import('@/components/layout/MainPage.vue'),
    VueEditor
  },
  data () {
    return {
      customToolbar: [
        ['bold', 'italic', 'link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['code-block', 'image']
      ],
      isViewFormDelete: false,
      newTag: '',
      isLoad: false,
      articleData: {},
      body: {
        title: '',
        content: '',
        is_published: false,
        language: 'En',
        tags: [],
        slug: '',
        meta_data: {}
      },
      html: [
        {
          tag: 'title',
          content: ''
        },
        {
          tag: 'description',
          content: ''
        },
        {
          tag: 'og:title',
          content: ''
        },
        {
          tag: 'og:description',
          content: ''
        }
      ],
      tags: [],
      AddWhite,
      DeleteRed
    }
  },
  validations: {
    body: {
      title: { required },
      slug: { required }
    }
  },
  mounted () {
    this.getInfo()
    this.getAllTags()
    document.addEventListener('keypress', this.saveHook)
  },
  beforeDestroy () {
    document.removeEventListener('keypress', this.saveHook)
  },
  computed: {
    languages () {
      return this.$i18n.availableLocales.map(item => ({
          name: this.$t(item),
          value: item.toUpperCase()
        })
      )
    },
    titleImg () {
      const target = this.articleData?.files?.find(item => item.is_title)
      if (target)
        return target.file
      return ''
    },
    tagsList () {
      if (!this.newTag)
        return this.tags
      let result = []
      if (this.newTag) {
        result.push({ id: 'new', tag: this.newTag })
      }
      return [...result, ...this.tags]
    }
  },
  methods: {
    ...mapActions(['getArticle', 'getTags', 'patchArticle', 'uploadArticleFile', 'removeArticleFile', 'deleteArticle']),
    setImage (content) {
      let result = content
      this.articleData.files.forEach(item => {
        const _url = item.file
        result = result.replace(`#${ item.id }`, `${ _url }`)
      })
      return result
    },
    prepareImage () {
      let result = this.body.content
      this.articleData.files.forEach(item => {
        const _url = item.file.replaceAll('&', '&amp;')
        result = result.replace(`${ _url }`, `#${ item.id }`)
      })
      return result
    },
    saveHook (e) {
      if ((e.ctrlKey || e.code === 'MetaLeft') && e.key === 's') {
        if (e.preventDefault) e.preventDefault()
        this.validation()
        e.returnValue = false
        window.focus()
      }
    },
    onAddHtmlTag () {
      this.html.push({
        tag: '',
        content: ''
      })
    },
    onRemoveHtmlTag (index) {
      this.html.splice(index, 1)
    },
    serializeHtml () {
      return this.html.reduce((acc, item) => {
        if (!item.tag) return acc
        acc[item.tag] = item.content
        return acc
      }, {})
    },
    async onDeleteArticle () {
      this.isLoad = true
      if (await this.deleteArticle(this.$route.params.id))
        this.$router.push({ name: 'article' })
      this.isLoad = false
    },
    onChangeTag () {
      if (this.newTag && !this.tags?.includes(this.newTag)) {
        this.tags.push({
          tag: this.newTag
        })
        this.newTag = ''
      }
    },
    onAddNewTag (newTag) {
      this.newTag = newTag
    },
    async onDownloaded (action) {
      if (!action.target.files[0]) {
        return
      }

      if (action.target.files[0].size > 5242880) {
        this.$notification.error('notify.imgSizeTooBig')
        return
      }
      await this.uploadFile(action.target.files[0], true)
    },
    async getAllTags () {
      const { results } = await this.getTags()
      this.tags = results
    },
    async uploadFile (file, is_title = false) {
      if (this.isLoad) return
      this.isLoad = true
      let formData = new FormData()
      formData.append('file', file)
      formData.append('is_title', is_title)
      const url = await this.uploadArticleFile({ body: formData, id: this.$route.params.id })
      this.isLoad = false
      if (!url) return
      this.articleData.files.push(url)
      return url
    },
    async removeFile (file) {
      if (this.isLoad) return
      this.isLoad = true
      const fileIndex = this.articleData.files.findIndex(item => item.file === file)
      const fileData = this.articleData.files[fileIndex]
      if (fileData) {
        await this.removeArticleFile({ id: this.$route.params.id, fileId: fileData.id })
        this.articleData.files.splice(fileIndex, 1)
      }
      this.isLoad = false
    },
    async onImageAdded (file, Editor, cursorLocation, resetUploader) {
      const url = await this.uploadFile(file)
      if (url)
        Editor.insertEmbed(cursorLocation, 'image', url.file)
      resetUploader()
    },
    onImageDeleted (file) {
      this.removeFile(file)
    },
    async getInfo (_data) {
      const id = this.$route.params.id

      this.articleData = _data || await this.getArticle(id)

      for (const key in this.body) {
        if (key === 'content') {
          this.body.content = this.setImage(this.articleData.content)
          continue
        }
        this.body[key] = this.articleData[key]
      }
      this.html = this.html.filter(item => item.tag)
      for (const key in this.body.meta_data) {
        const index = this.html.findIndex(item => item.tag === key)
        if (index !== -1) {
          this.html[index].content = this.body.meta_data[key]
        }
      }
      this.html.forEach(item => {
        if (item.content) return
        if (item.tag.includes('title'))
          item.content = this.articleData.title
        if (item.tag.includes('description'))
          item.content = this.articleData.slug
      })
    },
    validation () {
      this.$v.$touch()
      !this.$v.$invalid && this.send()
    },
    async send () {
      if (this.isLoad) return
      const body = { ...this.body }
      body.meta_data = this.serializeHtml()
      body.tags = body.tags.map(item => ({ tag: item?.tag || item }))
      body.content = this.prepareImage()
      const result = await this.patchArticle({ id: this.$route.params.id, body })
      if (result) {
        this.$router.push({ name: 'article' })
      }
      // this.
    },
    clearForm () {
      this.body = {
        title: '',
        content: '',
        is_published: false,
        language: 'EN',
        tags: [],
        slug: '',
        meta_data: {}
      }
      this.$v.$reset()
    }
  }
}
</script>
<style lang="sass" scoped>
@import "@/assets/sass/richEditor.sass"
.article-content::v-deep
  pre
    color: #fff
    background-color: #344054
    padding: 20px
    border-radius: 16px
    overflow-x: auto
  img
    width: 100% !important
.loader
  position: absolute
  width: 100%
  height: 100%
  z-index: 10
  background-color: rgba(16, 24, 40, 1)
.overflow-hidden
  overflow: hidden
.hidden
  display: none
.title-image
  max-width: 100%
.title-image-container
  overflow: hidden
  &:hover
    .remove-btn
      opacity: 1
.add-btn, .remove-btn
  cursor: pointer
  width: 100%
  height: 100%
.remove-btn
  opacity: 0
  transition: opacity 0.2s
  position: absolute
  top: 0
  left: 0
  background-color: rgba(16, 24, 40, 0.8)
.btn-img
  width: 30px
</style>