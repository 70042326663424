import Vue from 'vue'
import Vuex from 'vuex'

import mutations from '@/store/mutations'

import App from '@/store/modules/app'
import Profile from '@/store/modules/profile'
import Admin from '@/store/modules/admin'
import Payments from '@/store/modules/payments'
import Directory from '@/store/modules/directory'
import License from '@/store/modules/license'
import Articles from '@/store/modules/articles'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app: App,
    profile: Profile,
    admin: Admin,
    payments: Payments,
    directory: Directory,
    license: License,
    articles: Articles
  },
  mutations
})

export default store
