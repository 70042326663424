<template lang="pug">
  include ../../../plugins/pug/template

  div
    div.w-full
      div.d-flex.flex-row
        +select-label(
          'filter.language',
          'languages',
          'language',
          'name',
          'value',
          '',
          '',
          false
        )(
          @change="changePage"
        ).mr-1
        +select-label(
          'filter.is_published',
          'typesPublished',
          'Status',
          'name',
          'value',
          '',
          '',
          false
        )(
          @change="changePage"
        ).mr-1
      div.d-flex.flex-row
        +select-label(
          'filter.filter_tags',
          'tags',
          'tags',
          'tag',
          'tag',
          '',
          '',
          true
        )(
          chips
          clearable
          @change="changePage"
        )

    Table(
      ref="table"
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :pageCount="pageCount"
      :changePage="changePage"
      :isLoad="isLoad"
      :noDataText="$t('NoAvailableData')"
      :btnLinkFunction="btnLinkFunction"
    )
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {
    Table: () => import('@/components/layout/Table.vue')
  },
  name: 'TableArticle',
  data () {
    return {
      itemsPerPage: 20,
      currentPage: this.$route.query.page || 1,
      pageCount: 0,
      isLoad: true,
      items: [],
      tags: [],
      filter: {
        is_published: 'all',
        filter_tags: [],
        language: ''
      },
      headers: [
        {
          text: this.$t('Date'),
          value: 'released_at',
          align: 'center'
        },
        {
          text: this.$t('title'),
          value: 'title',
          align: 'center'
        },
        {
          text: this.$t('language'),
          value: 'language',
          align: 'center'
        },
        {
          text: this.$t('isPublished'),
          value: 'is_published',
          align: 'center'
        },
        {
          text: this.$t('view'),
          value: 'itemLink',
          sortable: false,
          align: 'center'
        }
      ]
    }
  },
  computed: {
    typesPublished () {
      return Object.keys(this.$t('typesPublished')).map((key) => ({
        value: key, name: this.$t('typesPublished')[key]
      }))
    },
    languages () {
      return [
        {
          name: this.$t('all'),
          value: 'all',
        },
        ...this.$i18n.availableLocales.map(item => ({
            name: this.$t(item),
            value: item.toUpperCase()
          })
        )

      ]
    }
  },
  mounted () {
    this.changePage()
    this.getAllTags()
  },
  methods: {
    ...mapActions(['getArticles', 'getTags']),
    async getAllTags () {
      const { results } = await this.getTags()
      this.tags = results
    },
    btnLinkFunction (item) {
      return { name: 'article-info', params: { id: item.id } }
    },
    async changePage () {
      this.isLoad = true

      const params = {
        ...this.$route.query,
        page_size: this.itemsPerPage,
        page: this.$route.query.page || this.currentPage || 1
      }

      for (const filter in this.filter) {
        if (filter === 'filter_tags') {
          if (this.filter[filter].length) {
            params.filter_tags = this.filter[filter].join(',')
          }
          continue
        }
        if (this.filter[filter] && this.filter[filter] !== 'all') {
          params[filter] = this.filter[filter]
        }
      }

      const data = await this.getArticles(params)
      if (!data?.results) return
      this.items = data.results
      this.pageCount = Math.ceil(data.count / this.itemsPerPage)
      this.isLoad = false
    }
  }
}
</script>

