import api from '@/plugins/api'
import { profile, user as endpoint } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'

const store = 'profile'

export default {
  state: () => ({
    // users: [],
    permissions: [],
    user: {},
    isLoadingUsers: false,
    isLoadingInfoUser: false
  }),
  actions: {
    async resetPassword (context, body) {
      const response = await api.post(`${ endpoint.userClient }reset_password/`, { body })
      return requestValidation(response, { isViewNotify: true, notifyTitle: 'notify.passwordReset' })
    },
    // async getUsers (context, params) {
    //   context.commit('SET_DATA', { store, state: 'isLoadingUsers', data: true })
    //
    //   let getParams = params && Object.keys(params)
    //     .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)
    //
    //   const response = await api.get(`${ endpoint.user }${ getParams ? `?${ getParams }` : ''} `)
    //   if (await requestValidation(response)) {
    //     context.commit('SET_DATA', { store, state: 'users', data: response.data })
    //   }
    //   context.commit('SET_DATA', { store, state: 'isLoadingUsers', data: false })
    // },

    async getProfile (context) {
      const response = await api.get(profile.me)

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'user', data: response.data })
      }
    },

    async postUser (context, body) {
      const response = await api.post(endpoint.create, { body: body })
      return await requestValidation(response, { isViewNotify: true })
    },

    async patchUser (context, { body, id }) {
      const response = await api.patch(`${ endpoint.user }${ id }/`, { body })
      if (await requestValidation(response, { isViewNotify: true, notifyTitle: 'notify.userUpdated' }))
        return response.data
    },
    async deleteUser (context, id) {
      const response = await api.delete(`${ endpoint.user }${ id }/`)
      return await requestValidation(response, { isViewNotify: true, notifyTitle: 'notify.userDeleted' })
    },
    async updateUserPassword (context, body) {
      const response = await api.post(`${ endpoint.user }password/`, { body })
      const isSuccess = await requestValidation(response, { isViewNotify: true, notifyTitle: 'notify.passwordUpdated' })
      // if (isSuccess) {
      //   context.commit('SET_DATA', { store, state: 'user', data: response.data })
      // }
      return isSuccess
    }
    // async patchUser (context, body) {
    //   const response = await api.patch(endpoint.action(body.id), { body: body })
    //   const isResult = await await requestValidation(response)
    //   if (isResult) {
    //     context.commit('UPDATE_DATA', { store, state: 'users', data: response.data })
    //     context.commit('SET_DATA', { store, state: 'userInfo', data: response.data })
    //   }
    //   return isResult
    // },
    // async deleteUser (context, id) {
    //   const response = await api.delete(endpoint.action(id))
    //   return await requestValidation(response)
    // },

  },
  getters: {

    userById: state => id => state.users.find(item => Number(item.id) === Number(id))
  }
}
