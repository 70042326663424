import en from '@/configs/translations/en'
import uk from '@/configs/translations/uk'
import hr from '@/configs/translations/hr'

let locale = 'en'

export default {
  locale,
  fallbackLocale: 'en',
  availableLocales: [{
    code: 'en',
    flag: 'us',
    label: 'English',
    messages: en
  }, {
    code: 'uk',
    flag: 'ua',
    label: 'Ukrainian',
    messages: uk
  }, {
    code: 'hr',
    flag: 'hr',
    label: 'Croatian',
    messages: hr
  }]
}

