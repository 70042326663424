<template lang="pug">
  div.loader-margin
    v-progress-circular(
      :size="50"
      color="primary"
      indeterminate).loader
</template>

<script>
export default {
  name: 'Main'
}
</script>

<style scoped lang="sass">
  .loader-margin
    margin-top: 50vh

  .loader
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
</style>

