<template lang="pug">
  include ../../../plugins/pug/template
  div
    v-card
      v-dialog(v-model="isViewForm" width="400" )
        FormCreateLog(
          v-if="isViewForm"
          :release_id="release.id"
          @onUpdate="onUpdate"
          @onClose="isViewForm = false"
        )
      v-card-title.font18.px-6.pt-6.mb-0.pb-0.d-flex.flex-row.justify-lg-space-between.align-center
        span {{ release.version }}
      v-card-text.mt-3.px-6.pb-5
        +info-row({ model: 'release.changelogs?.length', label: 'changelogsCount' }).mb-3
        +info-row({
          model: '$moment.tz(release.released_at, \'Europe/Kiev\').format(\'DD.MM.YYYY\')',
          label: 'releasedAt'
        }).mb-3
        +info-row({
          model: '$moment.tz(release.updated_at, \'Europe/Kiev\').format(\'DD.MM.YYYY\')',
          label: 'updatedAt'
        }).mb-0

    v-btn(color="primary-gradient-border" @click="isViewForm = true" large).main-button.w-full.mb-0.mt-6
      span {{ $t('addNewChange') }}


</template>

<script>

export default {
  name: 'changeLogInfo',
  components: {
    FormCreateLog: () => import('@/components/Forms/CreateLog.vue')
  },
  props: {
    release: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      isViewForm: false
    }
  },
  methods: {
    onUpdate () {
      this.$emit('onUpdate')
      this.isViewForm = false
    }
  }
}
</script>
