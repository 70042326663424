<template lang="pug">
  div
    div(v-if="isLoadingApp")
      Loader
    div(v-else)
      v-app(v-if="isGuestRoute" dark)
        div(:class="(isLoginRoute || isRegistrationRoute) && 'login-background'").justify-center.public-route
          router-view
          div.version {{ version }}
      v-app(v-else).ep
        Navigation
        v-main(:class="isMini ? 'v-main-mini' : 'v-main'")
          router-view
</template>

<script>
import Navigation from '@/components/Navigation'
import Loader from '@/components/Loader'
import { mapActions, mapState } from 'vuex'
import { version } from '@/configs/version'

export default {
  name: 'App',
  components: {
    Navigation,
    Loader
  },
  data () {
    return {
      isGuestRoute: true,
      isLoginRoute: false,
      isRegistrationRoute: false,
      version
    }
  },
  computed: {
    ...mapState({
      isLoadingApp: state => state.app.isLoad,
      isMini: state => state.app.isMini
    }),
    isAuth () {
      return this.$auth.isAuth()
    }
  },
  watch: {
    '$route' (route) {
      this.isGuestRoute = route.meta.public || false
      this.isLoginRoute = route.name === 'login' || false
      this.isRegistrationRoute = route.name === 'registration' || false
    }
  },
  // beforeMount () {
  //   if (this.isAuth) {
  //     this.getDirectories()
  //   }
  // },
  async mounted () {
    if (this.isAuth) {
      await this.setDataOnLogin()
    }
  },
  methods: {
    ...mapActions(['setDataOnLogin'])
  }
}
</script>
