<template lang="pug">
  div.d-flex.align-center
    span.font24
      span {{ license.name || $t('noNameServer') }}
      span(v-if="!isHideVersionUpdate").white--text.font-w500
        |  {{ $t('updateTo') }}
        a(href="https://docs.easypon.in/#updating-easypon" target="_blank").color-gradient.underline {{ license.latest_ep_version }}
    div.circle-status-tooltip
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          div.circle-status(v-bind="attrs" v-on="on" :class="{'circle-status--active': license.is_installed}").ml-2
        template(v-slot:default)
          span {{ $t(license.is_installed ? 'licenseInstalled': 'licenseInstalledFalse') }}
</template>

<script>
export default {
  name: 'LicenseName',
  props: {
    license: {
      type: Object,
      required: true
    },
    isHideVersionUpdate: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass" scoped>
.circle-status
  width: 10px
  height: 10px
  border-radius: 50%
  background-color: #F04438
  &-tooltip
    position: relative
    cursor: pointer
    display: block
  &--active
    background-color: #12B76A
</style>
