<template lang="pug">
  include ../../plugins/pug/template

  v-card(elevation="0")
    div.d-flex.justify-space-between.align-flex-start.mr-6.ml-2.title
      div.w-full(v-if="isShowTitle")
        v-card-title.font30 {{ title }}
        v-card-subtitle.gray--text.pb-1.pt-1.font16(v-if="subtitle") {{ subtitle }}
        slot(name="title" )
      div.btn-wrapper.pt-4
        +btn('primary-gradient','logout')(
          v-if="isShowLogout"
          large
          @click="logout"
        ).logout-btn
        slot(name="button" )

    v-card-text
      slot
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PageWithTable',
  props: {
    isShowLogout: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  },
  computed: {
    isShowTitle () {
      return !!this.title || !!this.subtitle || !!this.$slots.title
    }
  },
  methods: {
    ...mapActions(['resetUserData']),
    logout () {

      this.resetUserData()
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="sass" scoped>
.title
  position: relative
.logout-btn
  min-width: 118px !important
</style>
