<template lang="pug">
  include ../../plugins/pug/template

  v-card(tag="form" @submit.prevent="validation").bg-blue
    v-card-title.pa-8.pb-6.d-flex.flex-row.justify-space-between.align-center
      div.d-flex.flex-row.align-center
        span.font30 {{ $t('downloadEp') }}
        a(href='https://docs.easypon.in/how-to-install-easypon' target='_blank').ml-2
          v-icon(to="google.com")
            | mdi-help-circle-outline
      v-btn(icon @click="$emit('close')")
        img(:src="CloseDark")
    v-card-text.mb-0.pb-0.px-8
      v-tabs(
        v-model='tab'
        background-color="transparent"
      ).custom-tabs
        v-tab(key='debian').border-right
          | {{ $t('scriptForDebian') }}
        v-tab(key='docker')
          | {{ $t('dockerCompose') }}
    v-card-text.mb-0.pb-0.px-8.mt-6
      v-tabs-items(v-model='tab')
        v-tab-item(v-for="(tab, key) in tabs" :key="key")
          div.d-flex.flex-column.justify-start.w-full.input-vertical
            div(v-for="row in tab" :key="row.id")
              div.input-vertical-title.gray--text.text-left.text-truncate.font16 {{ row.title }}
              div(v-if="row.text").font14 {{ row.text }}
                code {{ rootPath }}
              v-textarea(
                v-if="row.value"
                :value="isCopy === row.id ? $t('copied') : row.value"
                :rows="row.valueRow"
                filled
                solo
                dense
                no-resize
                append-icon="mdi-content-copy"
                readonly
                @click:append="copyLink(row.value, row.id)"
                @click="copyLink(row.value, row.id)"
              ).cursor-pointer
          div(v-if="key === 'docker'").d-flex.flex-column.justify-center.align-center.pa-0.mb-5.mt-0
            v-btn(color="primary-gradient" @click="download"  large).main-button.w-full
              template(v-if="!isLoad")
                span {{ $t('DownloadEasyPON') }}
                v-icon(right, dark)
                  | mdi-tray-arrow-down
              template(v-else)
                span.mr-2 {{ $t('PleaseWait') }}
                v-progress-circular(indeterminate :width="2" :size="20")
</template>

<script>

import CloseDark from '@/assets/icons/close-dark.svg'
import { mapActions, mapGetters } from 'vuex'
import { copyTextToClipboard } from '@/mixins/main'

export default {
  name: 'FormLicenseDownload',
  props: {
    license: {
      type: Object,
      required: true
    },
    isLoad: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    mainCommand: `bash <(curl -k ${ document.location.origin }/install_ep.sh)`,
    rootPath: '/root/.tmp_ep_users',
    CloseDark,
    isCopy: '',
    tab: 'debian'
  }),
  computed: {
    ...mapGetters(['downloadLicenseLink']),
    tabs () {
      return {
        debian: this.curlLink,
        docker: this.dockerLink
      }
    },
    dockerLink () {
      // const location = this.downloadLicenseLink(this.license.id)
      // const token = this.getCookie('TOKEN')
      // const downloadLink = `curl '${ location }' -H 'Authorization: ${ token }' > easypon.tar`
      return [
        {
          id: 'installDocker',
          title: this.$t('installDocker'),
          value: `apt update && apt install curl -y\n${ this.mainCommand } docker-install`,
          valueRow: 2
        },
        {
          id: 'upgradeDocker',
          title: this.$t('upgradeDocker'),
          value: `${ this.mainCommand } docker-upgrade`,
          valueRow: 1
        },
        {
          id: 'repairDocker',
          title: this.$t('repairDocker'),
          value: `${ this.mainCommand } docker-repair`,
          valueRow: 1
        }
        // {
        //   id: 'manualDownload',
        //   title: this.$t('downloadDocker'),
        //   value: downloadLink,
        //   valueRow: 5
        // },
        // {
        //   id: 'accessingDebian',
        //   title: this.$t('accessingDocker'),
        //   text: this.$t('accessingText')
        // }
      ]
    },
    curlLink () {
      return [
        {
          id: 'installDebian',
          title: this.$t('installDebian'),
          value: `apt update && apt install curl -y\n${ this.mainCommand } install`,
          valueRow: 2
        },
        {
          id: 'upgradeDebian',
          title: this.$t('upgradeDebian'),
          value: `${ this.mainCommand } upgrade`,
          valueRow: 1
        },
        {
          id: 'repairDebian',
          title: this.$t('repairDebian'),
          value: `${ this.mainCommand } repair`,
          valueRow: 1
        }
        // {
        //   id: 'accessingDebian',
        //   title: this.$t('accessingDebian'),
        //   text: this.$t('accessingText')
        // }
      ]
    }
  },
  methods: {
    ...mapActions(['patchLicense', 'downloadLicense', 'createLicense']),
    async download () {
      this.$emit('startDownload', this.license.id)
      const result = await this.downloadLicense(this.license.id)
      if (result) {

        this.$emit('close')
        this.$notification.success(this.$t('notify.downloadSuccess'))
      } else {
        this.$notification.error(this.$t('notify.errorDownload'))
      }
      this.$emit('downloaded', this.license.id)
    },
    getCookie (name) {
      const value = `; ${ document.cookie }`
      const parts = value.split(`; ${ name }=`)
      if (parts.length === 2) return parts.pop().split(';').shift()
    },
    copyLink (text, id) {
      copyTextToClipboard(text)
      this.isCopy = id
      setTimeout(() => {
        this.isCopy = ''
      }, 500)
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../assets/sass/constants'

.theme--dark.v-tabs-items
  background-color: transparent !important
.theme--light.v-tabs-items
  background-color: transparent !important
.custom-tabs::v-deep
  margin-left: -14px
  .border-right
    border-right: 1px solid $gray-500
  .v-tabs-bar
    height: 30px
  .v-tab--active
    color: $white
  .v-tabs-slider-wrapper
    display: none !important
</style>
