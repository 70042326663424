<template lang="pug">
  include ../../plugins/pug/template

  v-card(tag="form" @submit.prevent="validation").bg-blue
    v-card-title.pa-8.pb-6.d-flex.flex-row.justify-space-between.align-center
      span.font30 {{ $t('createLog') }}
      v-btn(icon @click="$emit('onClose')")
        img(:src="CloseDark")
    v-card-text.mb-0.pb-0.px-8
      +field({
        model: 'body.description',
        label: 'description',
        validation: '["required"]',
      })
      +switch('body.is_active', 'is_active', 'is_active_false', '').mt-0.pa-0
    v-card-actions.d-flex.flex-row.align-center.mx-8.pa-0.mb-8.justify-space-between
      +btn-general('btn.add', 'row-right-white.svg', 'primary-gradient')(type="submit" :loading="isLoad" large).mb-0.w-full

</template>

<script>
import { required } from 'vuelidate/lib/validators'
import CloseDark from '@/assets/icons/close-dark.svg'
import { mapActions } from 'vuex'

export default {
  name: 'formCreateLog',
  props: {
    release_id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    isLoad: false,
    body: {
      description: '',
      is_active: true
    },
    CloseDark
  }),
  validations: {
    body: {
      description: {
        required
      }
    }
  },
  methods: {
    ...mapActions(['createLog']),

    validation () {
      this.$v.$touch()
      !this.$v.$invalid && this.send()
    },
    async send () {
      if (this.isLoad) return
      this.isLoad = true
      await this.createLog({ body: this.body, release_id: this.release_id })
      this.clearForm()
      this.$emit('onUpdate')
      this.isLoad = false
    },
    clearForm () {
      this.name = ''
      this.$v.$reset()
    }

  }
}
</script>
