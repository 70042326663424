<template lang="pug">
  include ../../../plugins/pug/template
  div
    v-card(
      v-for="license in licenses"
      :key="license.id"
    ).mb-6.license-block.bg-dark
      v-card-title.pa-6
        div.d-flex.flex-row.justify-lg-space-between.align-center.w-full
          LicenseTitle(:license="license" isHideVersionUpdate)
          v-btn(icon @click="showForm(license, 'edit')")
            img(:src="EditBlockDark")
      v-card-text.pa-6.pt-0
        v-row
          v-col.col-6.pl-0.pr-md-5.pr-lg-10.pb-0
            +info-row({ model: 'license.tariff.name', label: 'tariff' })
            v-divider.mb-5.mt-2
            +info-row({ model: '`${ $number(license.next_payment) }$`', label: 'Payment' })
            v-divider.mb-5.mt-2
            +info-row({ model: 'license.count_used_onus', label: 'onuAmount' })
            v-divider.mb-5.mt-2
            +info-row({ model: 'license.discount_amount ?  `${ license.discount_amount }%` : "-"', label: 'discount' })
            v-divider.mb-5.mt-2
            +info-row({ model: 'license.discount_amount ? license.discount_active_to || $t("infinity") : "-"', label: 'discountActivateTo' })
            v-divider.mb-5.mt-2
          v-col.col-6.pr-0.pl-md-5.pl-lg-10.pb-0
            +info-row({ model: 'license.ep_version', label: 'easyponVersion' })
            v-divider.mb-5.mt-2
            +info-row({ model: 'license.latest_ep_version', label: 'easyponVersionLatest' })
            v-divider.mb-5.mt-2
            +info-row({
              model: 'license.easypon_url || license.easypon_ip',
              href: 'generateLink(license)',
              label: 'ServerLink',
              class: 'custom-link'
            })(v-if="license.easypon_url || license.easypon_ip")
            +info-row({
              model: '$t("unknown")',
              label: 'ServerLink',
            })(v-else)
            v-divider.mb-5.mt-2
            +info-row({ model: 'license.server_status', label: 'serverStatus' })
            v-divider.mb-5.mt-2
            +info-row({
              model: '$t("Deactivate")',
              label: 'DeactivateLicense',
              class: 'deactivate cursor-pointer underline',
              click: 'showForm(license, \'deactivate\')'
            })(v-if="license.is_active")
            +info-row({
              model: '$t("Activate")',
              label: 'ActivateLicense',
              class: 'success--text cursor-pointer underline',
              click: 'showForm(license, \'activate\')'
            })(v-else)
            v-divider.mb-4.mt-2
          +field-area('comments[license.id]', 'comment', 4)(
            @change="editComment(license.id)"
          ).custom-bg-textarea
        v-card-actions.d-flex.flex-row.w-100.pa-0.ma-0
          v-btn(color="primary-gradient-border" @click="showForm(license, 'discount')"  large).main-button.w-50.mr-3.flex-shrink-1
            span {{ $t('editDiscount') }}
          v-btn(color="primary-gradient" @click="downloadHandler(license)"  large).main-button.w-50.ml-3.flex-shrink-1
            template(v-if="!downloadingIds.includes(license.id)")
              span {{ $t('DownloadEasyPON') }}
              v-icon(right, dark)
                | mdi-tray-arrow-down
            template(v-else)
              span.mr-2 {{ $t('PleaseWait') }}
              v-progress-circular(indeterminate :width="2" :size="20")
    v-dialog(v-model="isViewForm" :width="mode === 'download'? 700: 600" )
      FormDownloadLicense(
        v-show="mode === 'download'"
        ref="DownloadLicense"
        :license="tempLicense"
        :isLoad="downloadingIds.includes(tempLicense.id)"
        @close="closeForm"
        @startDownload="downloadingIds.push($event)"
        @downloaded="downloadingIds = downloadingIds.filter(item => item !== $event)"
      )
      FormLicenseInstance(
        v-show="mode === 'edit'"
        ref="EditLicense"
        type="edit"
        :title="$t('editServer')"
        :isLoad="isLoad"
        @send="edit"
        @close="closeForm"
      )
      FormDiscountLicense(
        v-show="mode === 'discount'"
        ref="DiscountLicense"
        :isLoad="isLoad"
        @send="edit"
        @close="closeForm"
      )
      FormDeactivateLicense(
        v-show="mode === 'deactivate' || mode === 'activate'"
        ref="DeactivateLicense"
        :target="tempLicense"
        :title="mode === 'deactivate' ? $t('DeactivateLicense'): $t('ActivateLicense')"
        :isLoad="isLoad"
        :type="mode"
        @send="edit"
        @close="closeForm"
      )
      FormLicenseInstance(
        v-show="mode === 'create'"
        ref="CreateLicense"
        type="create"
        :title="$t('addServer')"
        :isLoad="isLoad"
        @send="create"
        @close="closeForm"
      )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EditBlockDark from '@/assets/icons/edit-block-dark.svg'
import AddDark from '@/assets/icons/add-dark.svg'

export default {
  name: 'Server',
  components: {
    LicenseTitle: () => import('@/components/Blocks/Licenses/Title.vue'),
    FormDiscountLicense: () => import('@/components/Forms/DiscountLicense.vue'),
    FormLicenseInstance: () => import('@/components/Forms/ServerInstance.vue'),
    FormDeactivateLicense: () => import('@/components/Forms/DeactivateLicense.vue'),
    FormDownloadLicense: () => import('@/components/Forms/DownloadLicense.vue')
  },
  props: {
    author: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      comments: [],
      licenses: [],
      downloadingIds: [],
      isLoad: false,
      isViewForm: false,
      tempLicense: {},
      EditBlockDark,
      AddDark,
      mode: '',
      paymentDateNext: this.$moment().subtract(-1, 'month').startOf('month').format('DD.MM.YYYY')
    }
  },
  computed: {
    ...mapGetters(['tariffById'])
  },
  mounted () {
    this.changePage()
  },
  methods: {
    ...mapActions(['getAdminLicenses']),
    generateLink (license) {
      let link = license.easypon_url || license.easypon_ip
      if(!link.match(/(www|http:|https:)+[^\s]+[\w]/g)) link = `http://${link}`
      return link
    },
    async changePage () {

      this.isLoad = true
      const params = {
        page_size: 500,
        page: 1,
        author: this.author
      }
      const data = await this.getAdminLicenses(params)
      if (!data?.results) return
      this.licenses = data.results
      this.comments = data.results.reduce((acc, item) => {
        acc[item.id] = item.comment
        return acc
      }, {})
      this.isLoad = false
    },
    ...mapActions(['patchLicense', 'createLicense']),
    downloadHandler (license) {
      this.showForm(license, 'download')
    },
    showForm (license, mode) {
      let { name, discount_amount, discount_active_to, get_rc_versions, is_installed } = license
      this.tempLicense = license

      this.mode = mode
      this.isViewForm = !this.isViewForm
      this.clearForms()
      if (['edit', 'discount'].includes(mode))
        this.$nextTick(() => {
          this.$refs.EditLicense?.editForm({ name, get_rc_versions, is_installed })
          this.$refs.DiscountLicense?.editForm({ discount_amount, discount_active_to })
        })
    },
    editComment (id) {
      this.tempLicense = { id }
      this.edit({ comment: this.comments[id] })
    },
    async edit (originBody) {
      if (this.isLoad) return
      const body = {}
      for (let key in originBody) {
        if (originBody[key] !== this.tempLicense[key]) body[key] = originBody[key]
      }

      if (!Object.keys(body).length) return
      this.isLoad = true
      const isSuccess = await this.patchLicense({ body, id: this.tempLicense.id })
      if (isSuccess) {
        this.closeForm()
        this.changePage()
      }

      this.isLoad = false
    },

    async create (requestData) {

      if (this.isLoad) return
      this.isLoad = true

      const isSuccess = await this.createLicense(requestData)
      isSuccess && this.closeForm()

      this.isLoad = false
    },
    closeForm () {
      this.isLoad = false
      this.isViewForm = false
      this.mode = ''
      this.clearForms()
      this.changePage()
    },
    clearForms () {
      this.$refs.EditLicense?.clearForm()
      this.$refs.DiscountLicense?.clearForm()
      this.$refs.DeactivateLicense?.clearForm()
      this.$refs.CreateLicense?.clearForm()
    }
  }
}
</script>

<style lang="sass" scoped>

.custom-bg-textarea::v-deep
  .v-input__slot
    background: transparent !important

@import "@/assets/sass/constants"
.add-btn
  width: 72px !important
  height: 72px !important
  background-color: $blue-800 !important
  border-radius: 16px
//img
//  width: 16px

.custom-link
  color: $custom-link !important
  text-decoration: underline !important
.deactivate
  color: $error !important
.license-block
  border: 1px solid transparent !important
//.license-block:hover, .license-block--active
//  background: linear-gradient($blue-800, $blue-800) padding-box, $primary-gradient border-box !important
//  border: 1px solid transparent !important
//.license-block:not(.license-block--active)
//    border: 1px solid transparent !important
</style>