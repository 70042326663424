<template lang="pug">
  include ../../plugins/pug/template

  v-card.bg-blue
    v-card-title.pa-8.pb-6.d-flex.flex-row.justify-space-between.align-center
      span.font30 {{ $t('deleteArticle') }}
      v-btn(icon @click="$emit('onClose')")
        img(:src="CloseDark")
    v-card-text.mb-0.pb-0.px-8
      p.font16 {{ $t('deleteArticleDescription') }}
    v-card-actions.d-flex.flex-column.justify-center.align-center.mx-8.pa-0.mb-8
      +btn-general('btn.delete', 'row-right-white.svg', 'primary-gradient')(
        :loading="isLoad"
        large
        @click="$emit('onDelete')"
      ).mb-0.w-full

</template>

<script>
import CloseDark from '@/assets/icons/close-dark.svg'

export default {
  name: 'FormChangePassword',
  props: {
    isLoad: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    CloseDark
  })
}
</script>
