import moment from 'moment-timezone'
import i18n from '@/plugins/vue-i18n'
import router from '@/router'

export const convertTime = (date, format = 'DD.MM.YYYY HH:mm') => {
  if (!date) return i18n.t('statuses.unknow')

  const item = date === 'Now' ? moment() : date

  if ((new Date(item).getFullYear() - new Date().getFullYear()) > 200)
    return i18n.t('infinity')
  return moment(item).format(format)
}

export const downloadFile = (blob, name) => {
  const a = document.createElement('a')
  a.href = URL.createObjectURL(blob)
  a.download = name
  a.click()
  a.remove()
}

export const pushBackRoute = name => {
  const path = sessionStorage.getItem(router.currentRoute.path)
  path ? router.push(path) : name && router.push({ name })
}

export const logout = () => {
  localStorage.removeItem('TOKEN')
  localStorage.removeItem('isOutdatedRelease')
  document.cookie = 'TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  location = '/login'
}

export const getSortingParam = options => {
  if (options?.sortBy || options?.sortDesc) {
    const {
      sortBy: [by],
      sortDesc: [desc]
    } = options

    if (!by && !desc) return []
    return [`${ desc ? '-' : '' }${ by }`]
  }
}

export function objectsEqual (o1, o2) {
  const entries1 = Object.entries(o1).sort()
  const entries2 = Object.entries(o2).sort()
  if (entries1.length !== entries2.length) {
    return false
  }
  for (let i = 0; i < entries1.length; ++i) {
    if (entries1[i][0] !== entries2[i][0]) {
      return false
    }
    let target1 = entries1[i][1]
    let target2 = entries2[i][1]

    if (typeof target1 === 'object' && typeof target2 === 'object') {
      target1 = Array.isArray(target1) ? target1.sort() : JSON.stringify(target1)
      target2 = Array.isArray(target2) ? target2.sort() : JSON.stringify(target2)
    }

    if (target1?.toString() !== target2?.toString()) {
      return false
    }

  }
  return true
}

export function getParams (params) {
  return Object.entries(params).map(([key, value]) => `&${ key }=${ value }`).join('')
}

function fallbackCopyTextToClipboard (text) {
  const textArea = document.createElement('textarea')
  textArea.value = text

  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) {
    console.error(err)
  }
  document.body.removeChild(textArea)
}

export function copyTextToClipboard (text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text)
}