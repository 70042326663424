import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import router from '@/router/index'
import store from '@/store'
import i18n from '@/plugins/vue-i18n'
import notify from '@/plugins/notify'
import validate from '@/plugins/validation'
import auth from '@/plugins/auth'
import api from '@/plugins/api'
import number from '@/plugins/number'

import Vuelidate from 'vuelidate'
import VueClipboard from 'vue-clipboard2'
import moment from 'moment-timezone'
import VueTheMask from 'vue-the-mask'
import VueSweetalert2 from 'vue-sweetalert2'

import Table from '@/components/layout/Table'

import '@/assets/sass/index.sass'
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.component('Table', Table)

Vue.use(Vuelidate)
Vue.use(VueClipboard)
Vue.use(VueTheMask)
Vue.use(VueSweetalert2)
Vue.use(number)

Vue.prototype.$api = api
Vue.prototype.$notification = notify
Vue.prototype.$auth = auth
Vue.prototype.$valid = validate
Vue.prototype.$moment = moment

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
