const i18nModule = process.env.VUE_APP_REQUIRED_I18N

let i18n
if (i18nModule) {
  i18n = require(`${ process.env.VUE_APP_REQUIRED_I18N }`).default
} else {
  console.warn('Module i18n not found, add i18n module config in env as VUE_APP_REQUIRED_I18N')
}

class VALIDATION {
  validation (field, type = [], text = null) {
    let errors = []
    field && type.forEach((item) => {
      if (item === 'sameAs' && i18n) {
        field.$params[item].eq = i18n.t(`sameAs.${field.$params[item].eq}`)
      }
      field.$dirty && !field[item] && errors.push(
        i18n && !text ? i18n.t(`notify.validation.${item}`, field, field.$params[item]) : text
      )
    })
    return errors
  }
}

const validate = new VALIDATION()
export default validate
