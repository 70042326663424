<template lang="pug">
  MainPage(isShowLogout, :title="$t('licenses')")
    v-row
      v-col.col-12
        LicensesTable
</template>

<script>

export default {
  name: 'Licenses',
  components: {
    LicensesTable: () => import('@/components/Blocks/Licenses/Table.vue'),
    MainPage: () => import('@/components/layout/MainPage.vue')
  }
}
</script>
