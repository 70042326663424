<template lang="pug">
  div.logo
    svg(fill="none" viewBox="0 0 167 40" xmlns="http://www.w3.org/2000/svg")
      path(
        :class="{'path--hide': isMini}"
        class="path"
        d="M18.6874 27.3926V31.2725H0V6.35596H18.1891V10.2358H4.62736V16.7141H16.6585V20.5228H4.62736V27.3926H18.6874Z"
        fill="white")
      path(
        :class="{'path--hide': isMini}"
        class="path"
        d="M31.2183 12.0512C34.0184 12.0512 36.1541 12.7275 37.6254 14.0801C39.1204 15.409 39.8679 17.426 39.8679 20.1312V31.2725H35.6676V28.9588C35.1219 29.7894 34.3388 30.4301 33.3184 30.881C32.3217 31.3081 31.1115 31.5217 29.6877 31.5217C28.2639 31.5217 27.018 31.2844 25.9502 30.8098C24.8823 30.3114 24.0518 29.6351 23.4585 28.7808C22.889 27.9028 22.6043 26.918 22.6043 25.8265C22.6043 24.1179 23.2331 22.7534 24.4908 21.733C25.7722 20.6889 27.7774 20.1668 30.5064 20.1668H35.4185V19.8821C35.4185 18.5532 35.0151 17.5328 34.2082 16.8209C33.4252 16.109 32.2505 15.7531 30.6843 15.7531C29.6165 15.7531 28.5605 15.9192 27.5164 16.2514C26.496 16.5836 25.6298 17.0463 24.9179 17.6396L23.1738 14.4004C24.1704 13.6411 25.3688 13.0597 26.7689 12.6563C28.169 12.2529 29.6521 12.0512 31.2183 12.0512ZM30.6131 28.2825C31.7285 28.2825 32.7133 28.0333 33.5675 27.535C34.4455 27.013 35.0625 26.2773 35.4185 25.3281V23.1212H30.8267C28.2639 23.1212 26.9825 23.9636 26.9825 25.6485C26.9825 26.4553 27.3028 27.096 27.9435 27.5706C28.5842 28.0452 29.4741 28.2825 30.6131 28.2825Z"
        fill="white")
      path(
        :class="{'path--hide': isMini}"
        class="path"
        d="M52.1632 31.5217C50.6207 31.5217 49.1139 31.32 47.6426 30.9166C46.1713 30.5131 44.9967 30.0029 44.1187 29.386L45.8273 26.0044C46.6815 26.574 47.7019 27.0367 48.8884 27.3926C50.0987 27.7249 51.2852 27.891 52.4479 27.891C55.1057 27.891 56.4346 27.1909 56.4346 25.7909C56.4346 25.1264 56.0905 24.6637 55.4023 24.4027C54.7379 24.1416 53.6582 23.8925 52.1632 23.6552C50.597 23.4179 49.3156 23.145 48.3189 22.8365C47.346 22.528 46.4917 21.9941 45.7561 21.2347C45.0442 20.4516 44.6882 19.3719 44.6882 17.9955C44.6882 16.1921 45.4357 14.7564 46.9307 13.6885C48.4494 12.597 50.4902 12.0512 53.0531 12.0512C54.3582 12.0512 55.6634 12.2054 56.9685 12.5139C58.2737 12.7987 59.3415 13.1902 60.1721 13.6885L58.4635 17.0701C56.8499 16.1209 55.0345 15.6463 53.0175 15.6463C51.7123 15.6463 50.7156 15.848 50.0275 16.2514C49.363 16.6311 49.0308 17.1413 49.0308 17.782C49.0308 18.4939 49.3868 19.0041 50.0987 19.3126C50.8343 19.5973 51.9615 19.8702 53.4802 20.1312C54.9989 20.3685 56.2447 20.6414 57.2177 20.9499C58.1906 21.2584 59.0212 21.7805 59.7093 22.5161C60.4212 23.2517 60.7772 24.2959 60.7772 25.6485C60.7772 27.4282 60.006 28.852 58.4635 29.9199C56.9211 30.9877 54.8209 31.5217 52.1632 31.5217Z"
        fill="white"
      )
      path(
        :class="{'path--hide': isMini}"
        class="path"
        d="M82.7708 12.2647L73.872 32.8031C73.0414 34.8676 72.0329 36.3151 70.8464 37.1457C69.6599 38 68.2242 38.4271 66.5394 38.4271C65.5902 38.4271 64.6529 38.2729 63.7274 37.9644C62.8019 37.6559 62.0426 37.2287 61.4493 36.683L63.2291 33.4082C63.6562 33.8116 64.1545 34.132 64.7241 34.3693C65.3173 34.6066 65.9106 34.7252 66.5038 34.7252C67.2869 34.7252 67.9276 34.5235 68.4259 34.1201C68.948 33.7167 69.4226 33.0404 69.8498 32.0912L70.1701 31.3437L61.8765 12.2647H66.5038L72.4838 26.3248L78.4994 12.2647H82.7708Z"
        fill="white"
      )
      path(
        :class="{'path--hide': isMini}"
        class="path"
        d="M96.942 6.35596C99.1015 6.35596 100.976 6.71191 102.566 7.42381C104.18 8.13571 105.414 9.1561 106.268 10.485C107.122 11.8139 107.549 13.3919 107.549 15.2191C107.549 17.0226 107.122 18.6007 106.268 19.9533C105.414 21.2822 104.18 22.3025 102.566 23.0144C100.976 23.7263 99.1015 24.0823 96.942 24.0823H91.318V31.2725H86.6907V6.35596H96.942ZM96.7285 20.1668C98.7455 20.1668 100.276 19.7397 101.32 18.8854C102.364 18.0311 102.886 16.809 102.886 15.2191C102.886 13.6292 102.364 12.4071 101.32 11.5528C100.276 10.6986 98.7455 10.2714 96.7285 10.2714H91.318V20.1668H96.7285Z"
        fill="white"
      )
      path(
        :class="{'path--hide': isMini}"
        class="path"
        d="M166.046 6.35596V31.2725H162.237L148.497 14.4004V31.2725H143.906V6.35596H147.714L161.454 23.228V6.35596H166.046Z"
        fill="white"
      )
      g(
        :class="{centered: isMini}"
        filter="url(#filter0_d_1633_28427)"
      )
        path(
          d="M124.768 31.6284C122.229 31.6284 119.939 31.0827 117.899 29.9911C115.858 28.8758 114.256 27.3452 113.093 25.3993C111.931 23.4297 111.349 21.2347 111.349 18.8142C111.349 16.3938 111.931 14.2106 113.093 12.2647C114.256 10.2951 115.858 8.76455 117.899 7.67297C119.939 6.55766 122.229 6 124.768 6C127.308 6 129.598 6.55766 131.638 7.67297C133.679 8.76455 135.281 10.2833 136.444 12.2291C137.606 14.175 138.188 16.37 138.188 18.8142C138.188 21.2584 137.606 23.4534 136.444 25.3993C135.281 27.3452 133.679 28.8758 131.638 29.9911C129.598 31.0827 127.308 31.6284 124.768 31.6284ZM124.768 27.5706C126.43 27.5706 127.925 27.2028 129.253 26.4672C130.582 25.7078 131.626 24.6637 132.386 23.3348C133.145 21.9822 133.525 20.4753 133.525 18.8142C133.525 17.1531 133.145 15.6581 132.386 14.3292C131.626 12.9766 130.582 11.9325 129.253 11.1969C127.925 10.4375 126.43 10.0578 124.768 10.0578C123.107 10.0578 121.612 10.4375 120.283 11.1969C118.955 11.9325 117.91 12.9766 117.151 14.3292C116.392 15.6581 116.012 17.1531 116.012 18.8142C116.012 20.4753 116.392 21.9822 117.151 23.3348C117.91 24.6637 118.955 25.7078 120.283 26.4672C121.612 27.2028 123.107 27.5706 124.768 27.5706Z"
          fill="url(#paint0_linear_1633_28427)"
        )

      defs
        filter(id="filter0_d_1633_28427"
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
          height="48.2951" width="49.5054"
          x="100.016" y="0.333333"
        )
          feFlood(flood-opacity="0" result="BackgroundImageFix")
          feColorMatrix(
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          )
          feOffset(dy="5.66667")
          feGaussianBlur(stdDeviation="5.66667")
          feComposite(in2="hardAlpha" operator="out")
          feColorMatrix( type="matrix" values="0 0 0 0 1 0 0 0 0 0.207843 0 0 0 0 0.317647 0 0 0 0.24 0" )
          feBlend( in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_1633_28427" )
          feBlend( in="SourceGraphic" in2="effect1_dropShadow_1633_28427" mode="normal" result="shape" )

        linearGradient(
          id="paint0_linear_1633_28427"
          gradientUnits="userSpaceOnUse"
          x1="111.349"
          x2="138.188"
          y1="18.8142"
          y2="18.8142"
        )
          stop(stop-color="#FF512A")
          stop(offset="1" stop-color="#FF1974")


</template>
<script>
export default {
  props: {
    isMini: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="sass" scoped>
$svgWidth: 168px
.logo
  margin: 40px 0
  height: 40px
  width: 100%
  position: relative
  overflow: hidden
  .centered
    transform: translateX(-$svgWidth/4)
  svg
    position: absolute
    margin: auto
    top: 0
    left: calc(50% - ($svgWidth / 2))
    bottom: 0
    width: $svgWidth
    transition: all 0.3s
    transition-delay: 0s
    .path.path--hide
      opacity: 0
      transition-delay: 0s
    .path
      opacity: 1
      transition-delay: 0.3s
    path, g
      transition: all 0.3s
</style>