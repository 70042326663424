<template lang="pug">
  include ../plugins/pug/template

  v-navigation-drawer(
    v-model="drawer",
    :mini-variant="isMini",
    mini-variant-width="88",
    width="300",
    permanent,
    app).drawer
    div
      Logo(:isMini="isMini")

      v-list.mt-0.pt-0
        router-link(:to="item.link" v-for="(item, index) in menu",
          :key="item.index",)
          v-list-item(

            :class="isActiveLink(item.routeName) ? 'drawer-items--active' : null"
          ).drawer-items
            v-list-item-icon
              v-img.drawer-icons(
                :src="getIcon(index)",
                :class="isActiveLink(item.routeName) ? 'drawer-icons-active' : null")
            v-list-item-content
              v-list-item-title.white--text.font14 {{ item.title }}

      div.drawer-btn
        v-btn(@click="isMini = !isMini", text, :ripple="false")
          img(:src="Drawer")
          v-icon(small).drawer-btn-icon {{ isMini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}
    //div.w-full.d-flex.flex-column.align-center.justify-center
    +btn('primary-gradient','Documentation')( href="https://docs.easypon.in/dashboard", target="_blank", large, :class="isMini ? 'doc-btn--hide' : 'doc-btn--show'").mb-8.mr-6.ml-6
      //div.text-center
      //  div(v-if="!isMini").sub-header.text-center {{ version }}
      //  v-spacer(v-else).mb-16
</template>

<script>
import Drawer from '@/assets/icons/drawer.svg'
import { mapMutations, mapState } from 'vuex'
import { access } from '@/configs/permissions'
import { version } from '@/configs/version'

export default {
  components: {
    Logo: () => import('@/components/Logo.vue')
  },
  data () {
    return {
      // Logo,
      // LogoMini,
      Drawer,
      drawer: true,
      access,
      version
    }
  },
  computed: {
    menu () {
      return [
        {
          title: this.$t('users'),
          icon: 'user-white.svg',
          iconActive: 'user-primary.svg',
          link: '/accounts',
          routeName: 'accounts'
        },
        {
          title: this.$t('licenses'),
          icon: 'server-white.svg',
          iconActive: 'server-primary.svg',
          link: '/',
          routeName: 'main'
        },
        {
          title: this.$t('changelog'),
          icon: 'feather-white.svg',
          iconActive: 'feather-primary.svg',
          link: '/changelog',
          routeName: 'changelog'
        },
        {
          title: this.$t('articles'),
          icon: 'book-white.svg',
          iconActive: 'book-primary.svg',
          link: '/article',
          routeName: 'article'
        }
      ]
    },
    ...mapState({
      isMiniApp: state => state.app.isMini,
      profile: state => state.profile.user
    }),
    isMini: {
      get () {
        return this.isMiniApp
      },
      set (value) {
        this.SET_DATA({ store: 'app', state: 'isMini', data: value })
      }
    }
  },
  methods: {
    ...mapMutations(['SET_DATA']),
    getIcon (index) {
      const images = require.context('@/assets/icons')
      const icon = this.isActiveLink(this.menu[index].routeName) ? this.menu[index].iconActive : this.menu[index].icon
      return images('./' + icon)
    },
    isActiveLink (link) {
      return this.$route?.name.includes(link)
    }
  }
}
</script>
<style lang="sass" scoped>
.doc-btn
  &--show
    visibility: visible
    opacity: 1
    transition: all 0.3s
    transition-delay: 0.3s
  &--hide
    visibility: hidden
    opacity: 0
    transition: all 0.15s
</style>
