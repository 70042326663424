<template lang="pug">
  div.bg(fluid)
    .bg-img
      .bg-content.text-center
        h2 УПС!
        h3 ЩОСЬ ЗЛАМАЛОСЯ
        p Але ми вже працюємо над цим!
        .text-left
          h3 :(
      img(src="../assets/images/serverError.png", alt="")
</template>

<script>
export default {
  name: "ServerError",
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>

.bg {
  background-color: #9abddd;
  padding: 0;
}
.bg-img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
img {
  max-width: 50%;
}
h2 {
  font: {
    family: Roboto;
    weight: bold;
    style: normal;
    size: 185.938px;
    height: 218px;
  }
}
h3 {
  font: {
    family: Roboto;
    style: normal;
    weight: 500;
    size: 43.9677px;
    height: 52px;
  }
}
p {
  font: {
    family: Roboto;
    weight: normal;
    style: normal;
    size: 27.5534px;
    height: 32px;
  }
}

@media only screen and (max-width: 900px) {
  h2 {
    font-size: (185.93px/2);
    line-height: (218px/2);
  }
  h3 {
    font-size: (43.9677px/2);
    line-height: (52px/2);
  }
  p {
    font-size: (27.5534px/2);
    line-height: (32px/2);
  }
}

@media screen and (max-width: 500px) {
  .bg-img {
    flex-wrap: wrap;
  }
}
</style>
