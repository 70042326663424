<template lang="pug">
  include ../../../plugins/pug/template

  v-card
    v-dialog(v-model="isViewFormEditProfile" width="600" )
      FormProfileEdit(
        ref="EditProfile"
        :title="$t('editBillingAddress')"
        :isLoad="isLoad"
        mode="address"
        :profile="profile"
        @send="editProfile"
        @close="closeForm"
      )
    v-card-title.font18.px-6.pt-6.mb-0.pb-0.d-flex.flex-row.justify-lg-space-between.align-center
      span {{$t('paymentInformation')}}
      v-btn(icon @click="showFormProfileEdit")
        img(:src="EditBlockDark")
    v-card-subtitle.mt-0.px-6.mb-6.gray--text700.pb-0.pt-0.font16 {{$t('billingInformation')}}
    v-card-text.mt-3.px-6.pb-5
      +info-row({
        model: '$number(profile.month_cost)+" "+$t(\'units.dollar\')',
        label: 'monthlyPayment'
      }).mb-3
      +info-row({ model: 'paymentDateNext', label: 'paymentDateNext' }).mb-3
      +info-row({
        model: '$number(profile.balance)',
        label: 'balance',
        unit: 'units.dollar',
        class: 'success--text font24'
      })(v-if="Number(profile.balance) >= 0").mb-0
      +info-row({
        model: '$number(profile.balance)',
        label: 'balance',
        unit: 'units.dollar',
        class: 'error--text font24'
      })(v-else).mb-0
      template(v-if="profile.address")
        v-divider.mb-4.mt-4
        +info-row({ model: 'profile.address.country.name', label: 'country' })(v-if="profile.address.country").mb-3
        +info-row({ model: 'profile.address.region', label: 'region' })(v-if="profile.address.region").mb-3
        +info-row({ model: 'profile.address.city', label: 'city' })(v-if="profile.address.city").mb-3
        +info-row({ model: 'profile.address.street', label: 'street' })(v-if="profile.address.street").mb-3
        +info-row({ model: 'profile.address.zip_code', label: 'zip_code' })(v-if="profile.address.zip_code").mb-3

</template>

<script>
import { mapActions } from 'vuex'
import EditBlockDark from '@/assets/icons/edit-block-dark.svg'

export default {
  name: 'PaymentInfo',
  components: {
    FormProfileEdit: () => import('@/components/Forms/ProfileEdit.vue')
  },
  props: {
    profile: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      isLoad: false,
      isViewFormEditProfile: false,
      paymentDateNext: this.$moment().subtract(-1, 'month').startOf('month').format('DD.MM.YYYY'),
      EditBlockDark
    }
  },
  methods: {
    ...mapActions(['getProfile', 'patchUser']),
    showFormProfileEdit () {
      this.isViewConfirmEditProfile = false
      const { address } = this.profile
      this.isViewFormEditProfile = !this.isViewFormEditProfile
      this.$nextTick(() => {
        this.$refs.EditProfile.editAddress(address)
      })
    },
    async editProfile (originBody) {
      if (this.isLoad) return
      const body = { address: {} }
      if (this.profile?.address === null) {
        body.address = originBody.address
      } else {
        for (let key in originBody.address) {
          if (originBody.address[key] !== this.profile?.address[key]) body.address[key] = originBody.address[key]
        }
      }

      if (!Object.keys(body).length) return
      this.isLoad = true

      const userData = await this.patchUser({ body, id: this.profile.id })

      if (userData) {
        this.closeForm()
        this.$emit('onUpdate', userData)
      }
      this.isLoad = false
    },
    closeForm () {
      this.isLoad = false
      this.isViewFormEditProfile = false

      setTimeout(() => {
        this.$refs.EditProfile?.clearForm()
      }, 300)
    }
  }
}
</script>
