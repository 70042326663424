import notify from '@ananasbear/notify'

const defaultDataObject = { isViewNotify: true, getData: false, notifyTitle: '' }

export default async (response, data = defaultDataObject) => {
  const { isViewNotify } = data
  const { notifyTitle } = data
  switch (response.code) {

    case 201:
      if (isViewNotify) notify.success(`notify.createdSuccess`)
      return true
    case 200:
      if (isViewNotify && notifyTitle) notify.success(notifyTitle)
      return true
    case 204:
      if (isViewNotify && notifyTitle) notify.success(notifyTitle)
      return true

    case 400: {
      const errors = await response.data.json()
      if (!isViewNotify) return false

      switch (true) {
        case !!errors.email: {
          errors.email.includes('Enter a valid email address.') ?
            notify.error(`notify.validation.email`) :
            notify.error(`notify.userExist`)
          break
        }
        case !!errors.password: {
          notify.warning(`notify.serverErrors.Incorrectemailorpassword`)
          break
        }
        case !!errors.website: {
          notify.warning(`notify.websiteError`)
          break
        }
        case !!errors.phone_number: {
          notify.warning(`notify.validation.phoneNumber`)
          break
        }
        case !!(errors.old_password || errors.new_password): {
          const firstKey = Object.keys(errors)[0]
          const firstValue = typeof errors[firstKey] === 'string'
            ? errors[firstKey]
            : errors[firstKey][Object.keys(errors[firstKey])[0]]
          notify.warning(`notify.${ firstKey }.${ firstValue.replace(/[\s.,%]/g, '') }`)
          break
        }
        case !!errors.non_field_errors || !!errors.error: {
          const key = errors.non_field_errors ? 'non_field_errors' : 'error'
          const text = typeof errors[key] === 'string' ? errors[key] : errors[key][0]
          notify.warning(text.replace(/\.$/gm, ''))
          break
        }
        default:
          notify.warning(errors[0] || errors[Object.keys(errors)[0]])
      }

      return false
    }

    case 404:
      isViewNotify && notify.warning('notify.notExist')
      return false

    case 500:
      isViewNotify && notify.error('notify.serverError')
      return false

    case 504:
      isViewNotify && notify.error('notify.requestTimeout')
      return false

    default:
      return false
  }
}
