<template lang="pug">
  include ../../../plugins/pug/template

  v-card(tag="form" @submit.prevent="validation").bg-dark
    v-card-title.pa-6.d-flex.flex-row.justify-space-between.align-center
      span.font18 {{ $t('AddPayment') }}
    v-card-text.mb-0.px-6.pb-0
      div.d-flex.flex-row.align-end
        +field-label-validation({ model: 'body.amount', label: 'amount', validation: '["required", "decimal"]' })
        +btn-general('btn.create', 'row-right-white.svg', 'primary-gradient')( type="submit" :loading="isLoad" large).mb-7.ml-5
</template>

<script>
import { cash } from '@/configs/validationRules'
import { mapActions } from 'vuex'

export default {
  name: 'FormCash',
  props: {
    account: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    isLoad: false,
    body: {
      amount: null
    }
  }),
  validations: {
    body: cash
  },
  methods: {
    ...mapActions(['cashPayment']),
    validation () {
      this.$v.$touch()
      !this.$v.$invalid && this.send()
    },
    async send () {
      if (this.isLoad) return
      this.isLoad = true
      const body = {
        ...this.body
      }
      const { id } = this.account
      if (await this.cashPayment({ body, id })) {
        this.$emit('onSend')
        this.clearForm()
      }
      this.isLoad = false

    },

    clearForm () {
      this.body = {
        amount: null
      }
      this.$v.$reset()
    }

  }
}
</script>
