import api from '@/plugins/api'
import { profile } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'

export default {
  actions: {
    async downloadLicense (context, id) {
      try {
        const { data } = await api.get(profile.download(id), { responseType: 'blob' })
        const filename = `ep-${ id }.tar`

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(data, filename)
          return
        }

        const blobURL = window.URL.createObjectURL(data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL

        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank')
        }
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        setTimeout(() => {
          window.URL.revokeObjectURL(blobURL)
        }, 100)

        return true
      } catch (e) {
        return false
      }
    },

    async createLicense (context, body) {
      const response = await api.post(profile.license, { body })
      return requestValidation(response, { isViewNotify: true })
    },

    async setLicenseBannedStatus (context, { id, body }) {
      const notifyTitle = body.is_banned ? 'notify.licenseDeactivated' : 'notify.licenseActivated'

      const response = await api.post(`${ profile.license }${ id }/deactivate/`, { body })
      return requestValidation(response, { isViewNotify: true, notifyTitle })
    },

    async patchLicense (context, { body, id }) {
      const response = await api.patch(`${ profile.license }${ id }/`, { body })
      return requestValidation(response, { isViewNotify: true, notifyTitle: 'notify.licenseUpdated' })
    }
  },
  getters: {
    downloadLicenseLink: () => id => `${ process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API : document.location.origin + '/' }${ profile.download(id) }`
  }
}
