<template lang="pug">
  include ../../plugins/pug/template
  MainPage(:isShowLogout="false" :title="$t('articles')")
    template(#button)
      +btn('primary-gradient','btn.create')(
        large
        @click="createArticle"
      )
    v-row
      v-col.col-12
        v-dialog(v-model="isViewForm" width="600" )
          FormCreateArticle(
            @onClose="isViewForm = false"
          )
        ArticleTable
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Article',
  components: {
    FormCreateArticle: () => import('@/components/Forms/CreateArticle.vue'),
    ArticleTable: () => import('@/components/Blocks/Article/Table.vue'),
    MainPage: () => import('@/components/layout/MainPage.vue')
  },
  data () {
    return {
      isViewForm: false
    }
  },
  methods: {
    ...mapActions(['postArticle']),
    createArticle () {
      this.isViewForm = true
    }
  }
}
</script>
