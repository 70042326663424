export default {
  state: () => ({
    isLoad: false,
    isMini: false,
    country: [
      { abbr: 'UA', dial_code: '+380' },
      { abbr: 'PL', dial_code: '+48' },
      { abbr: 'USA', dial_code: '+1' }
    ]
  }),
  actions: {
    setDataOnLogin ({ dispatch }) {
      return Promise.allSettled([
        dispatch('getTariffs'),
        dispatch('getProfile')
      ])
    },
    resetUserData ({ commit }) {
      localStorage.removeItem('TOKEN')
      document.cookie = 'TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      commit('SET_DATA', { store: 'profile', state: 'user', data: {} })
    }
  },
  getters: {}

}
