<template lang="pug">
  include ../../plugins/pug/template

  v-card.bg-blue
    v-card-title.pa-8.pb-6.d-flex.flex-row.justify-space-between.align-center
      span.font30 {{ $t('removeLog') }}
      v-btn(icon @click="$emit('onClose')")
        img(:src="CloseDark")
    v-card-text.mb-0.pb-0.px-8
      p.font16 {{ $t('removeLogDescription') }}
    v-card-actions.d-flex.flex-column.justify-center.align-center.mx-8.pa-0.mb-8
      +btn-general('btn.remove', 'row-right-white.svg', 'primary-gradient')(
        :loading="isLoad"
        large
        @click="send"
      ).mb-0.w-full

</template>

<script>
import CloseDark from '@/assets/icons/close-dark.svg'
import { mapActions } from 'vuex'

export default {
  name: 'FormChangePassword',
  props: {
    release_id: {
      type: [Number, String],
      required: true
    },
    id: {
      type: [Number, String],
      required: true
    }
  },
  data: () => ({
    isLoad: false,
    CloseDark
  }),
  methods: {
    ...mapActions(['removeLog']),
    async send () {
      if (this.isLoad) return
      this.isLoad = true
      await this.removeLog({ release_id: this.release_id, id: this.id })
      this.isLoad = false
      this.$emit('onUpdate')
    }
  }
}
</script>
