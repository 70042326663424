<template lang="pug">
  div.block-discount(:class="`block-discount--${size}`")
    div.block-discount__text.white--text
      | {{percent}}{{$t('units.percent')}}
    v-icon
      | mdi-bookmark


</template>

<script>
export default {
  name: 'DiscountLabel',
  props: {
    percent: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: 'l'
    }
  }
}
</script>

<style lang="sass" scoped>
@import src/assets/sass/constants

.block-discount
  overflow: hidden
  position: relative
  &--l
    width: 37px
    height: 36px
    .v-icon
      font-size: 58px
  .v-icon, &__text
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin: auto
  .v-icon
    z-index: 1
    margin-top: -10px
  .mdi-bookmark
    background: $primary-gradient
    -webkit-background-clip: text
    background-clip: text
    -webkit-text-fill-color: transparent
  &__text
    margin-top: 5px
    z-index: 2
    text-align: center
    font-size: 11px

</style>