import api from '@/plugins/api'
import { admin } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
import { getParams } from '@/mixins/main'

const isViewNotify = { isViewNotify: true }

export default {
  state: () => ({}),
  actions: {
    async getAdminLicenses (context, { page_size = 10, page = 1, ...params }) {
      const _params = getParams(params)
      const response = await api.get(`${ admin.licenses() }?page_size=${ page_size }&page=${ page }${ _params }`)
      await requestValidation(response, { isViewNotify })
      return response.data
    },
    async getAdminAccounts (context, { page_size = 10, page = 1, ...params }) {
      const _params = getParams(params)
      const response = await api.get(`${ admin.accounts() }?page_size=${ page_size }&page=${ page }${ _params }`)
      await requestValidation(response, { isViewNotify })
      return response.data
    },
    async getAdminAccount (context, { id }) {
      const response = await api.get(admin.accounts(id))
      await requestValidation(response, { isViewNotify })
      return response.data
    },
    async cashPayment (context, { id, body }) {
      const response = await api.post(admin.cash(id), { body })
      await requestValidation(response, { isViewNotify })
      return response.data
    },
    async getReleases (context, { page_size = 10, page = 1 }) {
      const response = await api.get(`${ admin.releases() }?page_size=${ page_size }&page=${ page }`)
      await requestValidation(response, { isViewNotify })
      return response.data
    },
    async getRelease (context, { id }) {
      const response = await api.get(admin.releases(id))
      await requestValidation(response, { isViewNotify })
      return response.data
    },
    async createLog (context, { body, release_id }) {
      const response = await api.post(admin.changelogs({ release_id }), { body })
      await requestValidation(response, { isViewNotify })
      return response.data
    },
    async patchLog (context, { body, id, release_id }) {
      const response = await api.patch(admin.changelogs({ release_id, id }), { body })
      await requestValidation(response, { isViewNotify, notifyTitle: 'notify.updateLogSuccessful' })
      return response.data
    },
    async removeLog (context, { id, release_id }) {
      const response = await api.delete(admin.changelogs({ release_id, id }))
      await requestValidation(response, { isViewNotify, notifyTitle: 'notify.removeLogSuccessful' })
      return response.data
    }
  },
  getters: {}
}
