<template lang="pug">
  include ../../plugins/pug/template
  v-card(tag="form" @submit.prevent="validation" @reset="validation").bg-blue
    v-card-title.pa-8.pb-6.d-flex.flex-row.justify-space-between.align-center
      span.font30 {{ $t('editDiscount') }}
      v-btn(icon @click="$emit('close')")
        img(:src="CloseDark")
    v-card-text.mb-0.pb-0.px-8
      +field({
        model: 'body.discount_amount',
        label: 'discountAmount',
        validation: '["required", "maxValue"]',
        suffix: 'percent',
      })
      +date-picker-validation('body.discount_active_to', 'discountActiveTo', '["minDateValue"]')
    v-card-actions.d-flex.flex-row.align-center.mx-8.pa-0.mb-8.justify-space-between
      +btn-general('btn.reset', 'row-right-white.svg', 'grey-button')(:loading="isLoad" large @click="reset").mb-0.w-half
      +btn-general('btn.save', 'row-right-white.svg', 'primary-gradient')(type="submit" :loading="isLoad" large).mb-0.w-half
</template>

<script>
import { discount } from '@/configs/validationRules'
import CloseDark from '@/assets/icons/close-dark.svg'

export default {
  name: 'FormServerInstance',
  props: {
    isLoad: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    body: {
      discount_amount: null,
      discount_active_to: null
    },
    CloseDark
  }),
  validations: {
    body: discount
  },
  methods: {
    reset () {
      this.body.discount_amount = 0
      this.body.discount_active_to = null
    },
    validation () {
      this.$v.$touch()
      !this.$v.$invalid && this.send()
    },
    send () {
      if (this.isLoad) return
      const requestData = {
        ...this.body
      }
      this.$emit('send', requestData)
    },
    editForm (newBody) {
      this.clearForm()
      this.body = { ...newBody }
      if (!this.body.discount_active_to && !this.body.discount_amount) {
        this.body.discount_amount = null
      }
    },
    clearForm () {
      this.body = {
        discount_amount: null,
        discount_active_to: null
      }
      this.$v.$reset()
    }

  }
}
</script>
