import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#FF512A',
        secondary: '#667085',
        success: '#12B76A'
      },
    },
  },
})
