import iziToast from 'izitoast'
import 'izitoast/dist/css/iziToast.min.css'

const i18nModule = process.env.VUE_APP_REQUIRED_I18N
let i18n
if (i18nModule) {
  i18n = require(`${ process.env.VUE_APP_REQUIRED_I18N }`).default
} else {
  console.warn('Module i18n not found, add i18n module config in env as VUE_APP_REQUIRED_I18N')
}

let config
if (process.env.VUE_APP_NOTIFY_CONFIG) {
  config = require(`${ process.env.VUE_APP_NOTIFY_CONFIG }`)
} else throw Error('Path to config for module not found, add config as VUE_APP_NOTIFY_CONFIG in env')


iziToast.settings(config.default.global)

class Notify {
  info(text) {
    let msg = ''
    if (i18nModule) msg = text ? `${ i18n.t(text) }!` : 'Info'
    else msg = text ? text : 'Info'

    return iziToast.info(Object.assign(config.default.info, {
      message: msg,
    }))
  }
  success(
    text,
    i18nVariables = undefined,
    isStatic = false,
    onClosing = null
  ) {
    const msg = text
      ? (i18nModule && `${i18n.t(text, i18nVariables && {...i18nVariables})}!`) || text
      : 'Success'

    return iziToast.success(Object.assign(config.default.success, {
      message: msg,
      timeout: isStatic ? false : 20000,
      onClosing: onClosing || undefined
    }))
  }
  warning(text) {
    let msg = ''
    if (i18nModule) msg = text ? `${ i18n.t(text) }!` : 'Warning'
    else msg = text ? text : 'Warning'

    return iziToast.warning(Object.assign(config.default.warning, {
      message: msg,
    }))
  }
  error(text, error = '') {
    let msg = ''
    if (i18nModule) msg = text ? `${ i18n.t(text) }! ${ error }` : 'Error'
    else msg = text ? `${ text } ${ error }` : 'Error'

    return iziToast.error(Object.assign(config.default.error, {
      message: msg,
    }))
  }
}

const notify = new Notify()
export default notify
