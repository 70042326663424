<template lang="pug">
  include ../../plugins/pug/template

  v-card(tag="form" @submit.prevent="validation").bg-blue
    v-card-title.pa-8.pb-6.d-flex.flex-row.justify-space-between.align-center
      span.font30 {{ title }}
      v-btn(icon @click="$emit('close')")
        img(:src="CloseDark")
    v-card-subtitle.px-8.pt-4 {{  $t('DeactivateLicenseDescription', {name: target.name}) }}

    v-card-text.mb-0.pb-0.px-8
      +field({ model: 'name', label: 'LicenseName', validation: '["required"]' })(
        :error-messages="errorConfirmName"
        :class="errorConfirmName && 'sign__field__error__confirm'"
        @paste.prevent.stop
      )
    v-card-actions.d-flex.flex-column.justify-center.align-center.mx-8.pa-0.mb-8
      +btn-general('btn.deactivate', 'row-right-white.svg', 'primary-gradient')( v-if="type === 'deactivate'" type="submit" :loading="isLoad" large).mb-0.w-full
      +btn-general('btn.activate', 'row-right-white.svg', 'primary-gradient')( v-else type="submit" :loading="isLoad" large).mb-0.w-full


</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators'
import CloseDark from '@/assets/icons/close-dark.svg'

export default {
  name: 'FormDeactivateLicense',
  props: {
    title: {
      type: String,
      required: true
    },
    isLoad: {
      type: Boolean,
      default: false
    },
    target: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: 'deactivate'
    }
  },
  computed: {
    errorConfirmName () {
      if (
        this.name === this.target.name ||
        !this.$v.name.$dirty
      )
        return
      return this.$t('notify.matchingLicenseName')
    }
  },
  data: () => ({
    name: '',
    CloseDark
  }),
  validations: {
    name: {
      required,
      sameAs: sameAs(function () {
        return this.target.name
      })
    }
  },
  methods: {

    validation () {
      this.$v.$touch()
      !this.$v.$invalid && this.send()
    },
    send () {
      if (this.isLoad) return
      this.$emit('send', {
        is_active: !this.target.is_active
      })
    },
    clearForm () {
      this.name = ''
      this.$v.$reset()
    }

  }
}
</script>
