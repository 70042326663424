import { i18n } from '@/plugins/vue-i18n'
import '@/assets/sass/notifications.sass'
import InfoIcon from '@/assets/icons/notification-info.svg'
import SuccessIcon from '@/assets/icons/notification-success.svg'
import WarningIcon from '@/assets/icons/notification-warning.svg'
import ErrorIcon from '@/assets/icons/notification-error.svg'

export default {
  global: {
    close: true,
    pauseOnHover: true,
    timeout: 20000,
    progressBar: true,
    layout: 2,
    position: 'topRight',
  },

  info: {
    class: 'iziToast--info',
    iconUrl: InfoIcon,
    title: `${i18n.t('notify.info')}!`,
    position: 'topRight',
  },

  success: {
    class: 'iziToast--success',
    iconUrl: SuccessIcon,
    title: `${i18n.t('notify.success')}!`,
    position: 'topRight',
  },

  warning: {
    class: 'iziToast--warning',
    iconUrl: WarningIcon,
    title: `${i18n.t('notify.warning')}!`,
    position: 'topRight',
  },

  error: {
    class: 'iziToast--error',
    iconUrl: ErrorIcon,
    title: `${i18n.t('notify.error')}!`,
    position: 'topRight',
  }
}
