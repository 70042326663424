<template lang="pug">
  include ../plugins/pug/template
  div.sign
    v-card.sign__wrapper.pa-8
      v-card-title.sign__title
        span {{ $t('signIn') }}
      v-card-text.sign__inner
        +field({ model: 'body.email', label: 'email', validation: '["required", "email"]' })(
          v-on:keyup.enter="validation"
        )
        +field({ model: 'body.password', label: 'password', validation: '["required"]' })(
          :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="isShowPassword ? 'text' : 'password'"
          @click:append="isShowPassword = !isShowPassword"
          v-on:keyup.enter="validation"
        )

      v-card-actions.d-flex.flex-column.justify-center.align-center.mt-0.px-0.pt-2
        +btn-general('btn.sign', 'row-right-white.svg', 'primary-gradient')(@click="validation" :loading="isLoad").mb-0.w-full
          //div(id="buttonDiv")
</template>

<script>
import { login } from '@/configs/validationRules'
import requestValidation from '@/configs/requestValidation'
import { mapActions } from 'vuex'

export default {
  data: () => ({
    isShowPassword: false,
    isLoad: false,
    isViewFinger: false,
    isViewQR: false,
    isViewCode: false,
    body: {
      // email: 'aaa@gmail.com',
      // password: '3333',
      email: '',
      password: ''
    }
  }),
  validations: {
    body: login
  },
  mounted () {
    if (this.$auth.isAuth()) this.$router.push('/')
    // const google = window.google
    // console.log(google)
    // window.onload = function () {
    //   google.accounts.id.initialize({
    //     client_id: "204308686314-8l7uo6dmuqpifknemsa70v5dglifiksj.apps.googleusercontent.com", //.apps.googleusercontent.com
    //     callback: this.handleCredentialResponse,
    //     login_uri: `${process.env.VUE_APP_API}api/v1/accounts/google_oauth2/login/`
    //   });
    //   google.accounts.id.renderButton(
    //       document.getElementById("buttonDiv"),
    //       { theme: "outline", size: "large" }  // customization attributes
    //   );
    //   google.accounts.id.prompt(); // also display the One Tap dialog
    //
    //   // google.accounts.id.cancel()
    // }
  },
  methods: {
    ...mapActions(['setDataOnLogin']),

    // handleCredentialResponse (response) {
    //   console.log(response)
    //   console.log("Encoded JWT ID token: " + response.credential);
    // },
    validation () {
      this.$v.$touch()
      !this.$v.$invalid && this.sign()
    },
    async sign () {
      if (this.isLoad) return
      this.isLoad = true
      const auth = await this.$auth.byCredits(this.body)
      if (auth?.code === 400) await requestValidation(auth)
      if (auth?.type === 'general') {
        await this.setDataOnLogin()
        const path = decodeURIComponent(window.location.search.replace('?path=', ''))
        this.$router.push(path || '/')
      }

      this.isLoad = false
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/sass/sign"
</style>
