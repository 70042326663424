<template lang="pug">
  include ../../plugins/pug/template

  v-card(tag="form" @submit.prevent="validation").bg-blue
    v-card-title.pa-8.pb-6.d-flex.flex-row.justify-space-between.align-center
      span.font30 {{ $t('createArticle') }}
      v-btn(icon @click="$emit('onClose')")
        img(:src="CloseDark")
    v-card-text.mb-0.pb-0.px-8
      +field({
        model: 'body.title',
        label: 'title',
        validation: '["required", "maxLength"]',
      })(@input="onChange")
      +field({
        model: 'body.slug',
        label: 'slug',
        validation: '["required", "maxLength"]',
      })
      +select({
        model: 'body.language',
        items: 'languages',
        itemText: 'name',
        itemValue: 'value',
        label: 'language',
        validation: '["required"]'
      })
    v-card-actions.d-flex.flex-row.align-center.mx-8.pa-0.mb-8.justify-space-between
      +btn-general('btn.create', 'row-right-white.svg', 'primary-gradient')(type="submit" :loading="isLoad" large).mb-0.w-full

</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
import CloseDark from '@/assets/icons/close-dark.svg'
import { mapActions } from 'vuex'

const slugify = require('slugify')

export default {
  name: 'formCreateArticle',
  data: () => ({
    isLoad: false,
    body: {
      title: '',
      slug: '',
      language: 'EN'
    },
    CloseDark
  }),
  validations: {
    body: {
      title: {
        required,
        maxLength: maxLength(255)
      },
      slug: {
        required,
        maxLength: maxLength(63)
      },
      language: {
        required
      }
    }
  },
  computed: {
    languages () {
      return this.$i18n.availableLocales.map(item => ({
          name: this.$t(item),
          value: item.toUpperCase()
        })
      )
    }
  },
  methods: {
    ...mapActions(['postArticle']),

    validation () {
      this.$v.$touch()
      !this.$v.$invalid && this.send()
    },
    onChange () {
      this.body.slug = slugify(this.body.title, {
        replacement: '-',
        remove: /[*+~.()'"!:;@,?]/g,
        lower: true
      })
    },
    async send () {
      if (this.isLoad) return
      this.isLoad = true
      const data = await this.postArticle({ ...this.body })
      if (data.id) {
        this.$router.push({ name: 'article-info', params: { id: data.id } })
        this.clearForm()
      }
      this.isLoad = false
    },
    clearForm () {
      this.name = ''
      this.$v.$reset()
    }

  }
}
</script>
