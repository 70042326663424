import api from '@/plugins/api.js'
import { directory } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'

const store = 'directory'

export default {
  state: () => ({
    country: [
      { abbr: 'UA', dial_code: '+380' },
      { abbr: 'PL', dial_code: '+48' },
      { abbr: 'USA', dial_code: '+1' }
    ],
    countries: [],
    tariffs: {}
  }),
  actions: {
    async getTariffs (context) {
      const response = await api.get(directory.tariff)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'tariffs', data: response.data })
      }
      return response
    },
    async getCountries ({ state }) {
      if (state.countries.length === 0) {
        const response = await api.get(directory.countries)
        if (await requestValidation(response))
          for (const code in response.data) {
            state.countries.push({ code, name: response.data[code] })
          }
      }

      return state.countries
    }
  },
  getters: {
    tariffById: state => id => state.tariffs?.results?.find(item => item.id === id)
  }
}
