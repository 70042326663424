<template lang="pug">
  include ../../plugins/pug/template

  v-card(tag="form" @submit.prevent="validation").bg-blue
    v-card-title.pa-8.pb-6.d-flex.flex-row.justify-space-between.align-center
      span.font30 {{ title }}
      v-btn(icon @click="$emit('close')")
        img(:src="CloseDark")
    v-card-text.mb-0.pb-0.px-8
      div(v-if="mode === 'default'" )
        +field({ model: 'body.first_name', label: 'name', validation: '["required"]' })
        +field({ model: 'body.last_name', label: 'surname', validation: '["required"]' })
        +field({ model: 'body.email', label: 'email', validation: '["required", "email"]' })
        PhoneNumberField(
          v-model="body.phone_number"
          :profile="profile"
          :default-value="body.phone_number"
          :error-messages="$valid.validation($v.body.phone_number, ['required', 'minLength', 'maxLength'])"
        )
        +field({ model: 'body.company_name', label: 'companyName', validation: '["required"]' })
        +field({
          model: 'body.website',
          label: 'website',
          validation: '["required"]',
          prefix: 'https'
        })(
          @change="cropWebsite",
          @paste.stop="cropWebsite"
        ).sign__field
        v-checkbox(
          v-model="isAdmin"
          :label="$t('makeMeAdmin')"
        ).mt-0
      div(v-else)
        +select({
          model: 'address.country',
          items: 'countries',
          itemText: 'name',
          itemValue: 'code',
          label: 'country',
          validation: '["required"]'
        })
        +field({ model: 'address.region', label: 'region', validation: '["required"]' })
        +field({ model: 'address.city', label: 'city', validation: '["required"]' })
        +field({ model: 'address.street', label: 'street', validation: '["required"]' })
        +field({ model: 'address.zip_code', label: 'zip_code', validation: '["required"]' })
    v-card-actions.d-flex.flex-column.justify-center.align-center.mx-8.pa-0.mb-8
      +btn-general('btn.save', 'row-right-white.svg', 'primary-gradient')(type="submit" :loading="isLoad" :disabled="isLoad" large).mb-0.w-full

</template>

<script>
import { email, maxLength, minLength, required } from 'vuelidate/lib/validators'
import CloseDark from '@/assets/icons/close-dark.svg'
import { mapActions } from 'vuex'

export default {
  name: 'FormProfileEdit',
  components: {
    PhoneNumberField: () => import('@/components/PhoneNumberField')
  },
  props: {
    profile: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isLoad: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'default'
    }
  },
  data: () => ({
    isAdmin: false,
    body: {
      first_name: null,
      last_name: null,
      company_name: null,
      email: null,
      website: null,
      phone_number: null,
      type_user: null
    },
    address: {
      country: null,
      region: null,
      city: null,
      street: null,
      zip_code: null
    },
    countries: [],
    CloseDark
  }),
  validations: {
    address: {
      country: { required },
      region: { required },
      city: { required },
      street: { required },
      zip_code: { required }
    },
    body: {
      first_name: { required },
      last_name: { required },
      company_name: { required },
      website: { required },
      email: { required, email },
      phone_number: {
        required,
        minLength: minLength(11),
        maxLength: maxLength(13)
      }
    }
  },
  async mounted () {
    this.countries = await this.getCountries()
  },
  methods: {
    ...mapActions(['getCountries']),
    validation () {
      const mode = this.mode === 'default' ? 'body' : 'address'
      this.$v[mode].$touch()
      !this.$v[mode].$invalid && this.send()
    },
    cropWebsite () {
      setTimeout(() => {
        if (this.body.website)
          this.body.website = this.body.website.replace(/^https?:\/\//, '')
      })
    },
    send () {
      if (this.isLoad) return

      let website = ''
      this.body.type_user = this.isAdmin ? 'Admin' : 'Client'
      if (this.body.website) {
        website = this.body.website.includes('http') ?
          this.body.website : `https://${ this.body.website }`
      }

      const requestData = {
        ...this.body,
        website
      }
      if (this.mode === 'address')
        requestData.address = this.address
      this.$emit('send', { ...requestData })
    },
    editBody (newBody) {
      this.clearForm()
      this.isAdmin = newBody.type_user === 'Admin'
      this.body = { ...newBody }
      this.cropWebsite()
    },
    editAddress (newAddress) {
      this.clearForm()
      this.address = { ...newAddress }
    },
    clearForm () {
      this.body = {
        first_name: null,
        last_name: null,
        company_name: null,
        email: null,
        website: null,
        phone_number: null
      }
      this.address = {
        country: null,
        region: null,
        city: null,
        street: null,
        zip_code: null
      }
      this.$v.$reset()
    }

  }
}
</script>
