<template lang="pug">
  include ../../plugins/pug/template

  v-card(tag="form" @submit.prevent="validation").bg-blue
    v-card-title.pa-8.pb-6.d-flex.flex-row.justify-space-between.align-center
      span.font30 {{ title }}
      v-btn(icon @click="$emit('close')")
        img(:src="CloseDark")
    v-card-text.mb-0.pb-0.px-8
      +field({ model: 'body.name', label: 'name', validation: '["required"]' })
      +switch('body.get_rc_versions', 'getRcVersions', 'getRcVersionsFalse', '').mt-0.pa-0
      div.d-flex.flex-row.align-start
        +switch('body.is_installed', 'isInstalled', 'isInstalledFalse', '').mt-0.pa-0
        v-tooltip(right max-width="350")
          template(v-slot:activator="{ on, attrs }")
            img(v-bind="attrs" v-on="on" :src="WarningIcon" alt="Warning Icon").ml-3.mt-3.cursor-pointer
          template(v-slot:default)
            span {{ $t('licenseInstalledWarning') }}
    v-card-actions.d-flex.flex-column.justify-center.align-center.mx-8.pa-0.mb-8
      +btn-general('btn.create', 'row-right-white.svg', 'primary-gradient')(v-if="type === 'create'" type="submit" :loading="isLoad" large).mb-0.w-full
      +btn-general('btn.save', 'row-right-white.svg', 'primary-gradient')(v-else type="submit" :loading="isLoad" large).mb-0.w-full

</template>

<script>
import { server } from '@/configs/validationRules'
import CloseDark from '@/assets/icons/close-dark.svg'
import WarningIcon from '@/assets/icons/notification-warning.svg'

export default {
  name: 'FormServerInstance',
  props: {
    type: {
      type: String,
      default: 'create',
      validator (value) {
        return ['create', 'edit'].includes(value)
      }
    },
    title: {
      type: String,
      required: true
    },
    isLoad: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    body: {
      name: null,
      get_rc_versions: false,
      is_installed: false,
      support_language: 'Ukrainian'
    },
    CloseDark,
    WarningIcon
  }),
  validations: {
    body: server
  },
  methods: {
    validation () {
      this.$v.$touch()
      !this.$v.$invalid && this.send()
    },
    send () {
      if (this.isLoad) return
      const requestData = {
        ...this.body,
      }
      this.$emit('send', requestData)
    },
    editForm (newBody) {
      this.clearForm()
      this.body = { ...newBody }
    },
    clearForm () {
      this.body = {
        name: null,
        is_installed: false,
        get_rc_versions: false,
        support_language: 'Ukrainian'
      }
      this.$v.$reset()
    }

  }
}
</script>

<style lang="sass" scoped>
.max-width-200
  width: 200px
</style>
