<template lang="pug">
  MainPage(isShowLogout, :title="$t('changelog')")
    v-row
      v-col.col-8
        ChangelogList(:release="release" @onUpdate="getReleaseInfo").mb-6
      v-col.col-4
        ChangelogInfo(:release="release" @onUpdate="getReleaseInfo").mb-6
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'changelogInfo',
  components: {
    ChangelogList: () => import('@/components/Blocks/Changelog/List.vue'),
    ChangelogInfo: () => import('@/components/Blocks/Changelog/Info.vue'),
    MainPage: () => import('@/components/layout/MainPage.vue')
  },
  data () {
    return {
      release: {}
    }
  },
  mounted () {
    this.getReleaseInfo()
  },
  methods: {
    ...mapActions(['getRelease']),
    async getReleaseInfo () {
      const id = this.$route.params.id
      this.release = await this.getRelease({ id })
    }
  }
}
</script>
