<template lang="pug">
  include ../../../plugins/pug/template
  div
    div.w-full.d-flex.flex-row
      +select-label(
        'typeUser',
        'typesUser',
        'typeUser',
        'name',
        'value',
        '',
        ''
      )(
        required
        @change="changePage"
      ).mr-1

      +field-label-validation({ model: 'search', label: 'search', validation: '', appendIcon: 'mdi-magnify' })(
        @change="changePage"
      )
    Table(
      ref="table"
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :pageCount="pageCount"
      :changePage="changePage"
      :isLoad="isLoad"
      :noDataText="$t('NoAvailableData')"
      :btnLinkFunction="btnLinkFunction"
    )
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {
    Table: () => import('@/components/layout/Table.vue')
  },
  name: 'TableLicense',
  data () {
    return {
      typeUser: 'All',
      search: '',
      itemsPerPage: 20,
      currentPage: this.$route.query.page || 1,
      pageCount: 0,
      isLoad: true,
      items: [],
      headers: [
        {
          text: this.$t('companyName'),
          value: 'company_name',
          align: 'center'
        },
        {
          text: this.$t('clientName'),
          value: 'full_name',
          align: 'center'
        },
        {
          text: this.$t('balance'),
          value: 'balance',
          align: 'center'
        },
        {
          text: this.$t('location'),
          value: 'address',
          align: 'center'
        },
        {
          text: this.$t('view'),
          value: 'itemLink',
          sortable: false,
          align: 'center'
        }
      ]
    }
  },
  computed: {
    typesUser () {
      return Object.keys(this.$t('typesUser')).map((key) => ({
        value: key, name: this.$t('typesUser')[key]
      }))
    }
  },
  mounted () {
    this.changePage()
  },
  methods: {
    ...mapActions(['getAdminAccounts']),
    btnLinkFunction (item) {
      return { name: 'accounts-info', params: { id: item.id } }
    },
    async changePage () {
      this.isLoad = true

      const params = {
        ...this.$route.query,
        page_size: this.itemsPerPage,
        page: this.$route.query.page || this.currentPage || 1
      }

      if (this.typeUser !== 'All' && this.typeUser)
        params.type_user = this.typeUser
      if (this.search)
        params.search = this.search

      const data = await this.getAdminAccounts(params)

      if (!data?.results) return
      this.items = data.results
      this.pageCount = Math.ceil(data.count / this.itemsPerPage)
      this.isLoad = false
    }
  }
}
</script>

