<template lang="pug">
  include ../../../plugins/pug/template
  div
    v-dialog(v-model="isViewForm" width="600" )
      FormDeleteLog(
        v-if="isViewForm"
        :id="removeId"
        :release_id="$route.params.id"
        @onUpdate="onUpdate"
        @onClose="isViewForm = false"
      )
    v-card.mb-6
      v-card-title.px-0.py-5.d-flex.flex-column.background-blue-800
        div.d-flex.flex-row.justify-space-between.align-center.w-full
          div.switch-column.pa-0.d-flex.flex-shrink-0.ml-9.mr-3
            span.font16.font-weight-600 {{ $t('check') }}
          div.w-full.px-6
            span.font16.font-weight-600 {{ $t('description') }}
      div(v-if="!release.changelogs?.length").d-flex.flex-row.justify-center.align-center.w-full.pa-9
        span {{ $t('noData') }}
      v-card-title(
        v-for="(log, key) in release.changelogs"
        :key="log.id"
      ).pa-0.d-flex.flex-column
        div.d-flex.flex-row.justify-space-between.align-center.w-full
          div.switch-column.pa-0.d-flex.flex-row.flex-shrink-0.ml-9.mr-3
            +switch('forms[log.id].is_active', '', '', '')(
              @change="editActive(log.id, forms[log.id].is_active)"
            )
          div.w-full.px-6
            span.font16.font-weight-600 {{ log.description  }}
          div.px-6.d-flex.flex-row.justify-space-between.align-center
            v-btn(icon @click="toggleForm(log.id)").edit-btn
              img(:src="forms[log.id].is_edit ? EditBlockPrimary : EditBlockDark")
            v-btn(icon @click="showDeleteForm(log.id)").edit-btn
              img(:src="CloseDark")
        div(v-if="forms[log.id].is_edit").d-flex.flex-row.justify-lg-space-between.align-center.w-full.px-6
          +select-label(
            'forms[log.id].release',
            'releasesList',
            'version',
            'version',
            'id',
            '',
            ''
          ).mr-3
          +field-label-validation({
            model: 'forms[log.id].description',
            label: 'description',
            isRequired: true
          }).mr-3

          v-btn(color="primary-gradient-border" @click="toggleForm(log.id)"  large).w-50.main-button.mr-3.flex-shrink-1
            span {{ $t('cancel') }}
          v-btn(color="primary-gradient" @click="editDescription(log.id, forms[log.id])"  large).main-button.w-50.ml-3.flex-shrink-1
            span {{ $t('save') }}
        v-divider(v-if="release.changelogs.length !== key + 1").w-full
</template>

<script>
import { mapActions } from 'vuex'
import EditBlockDark from '@/assets/icons/edit-block-dark.svg'
import EditBlockPrimary from '@/assets/icons/edit-block-primary.svg'
import AddDark from '@/assets/icons/add-dark.svg'
import CloseDark from '@/assets/icons/close-dark.svg'

export default {
  name: 'changeLogList',
  components: {
    FormDeleteLog: () => import('@/components/Forms/DeleteLog.vue')
  },
  props: {
    release: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    release () {
      this.setForms()
    }
  },
  data () {
    return {
      removeId: null,
      isViewForm: false,
      releasesList: [],
      forms: {},
      isActive: false,
      CloseDark,
      EditBlockDark,
      EditBlockPrimary,
      AddDark
    }
  },
  mounted () {
    this.setForms()
    this.setReleases()
  },
  methods: {
    ...mapActions(['patchLog', 'getReleases']),
    toggleForm (id) {
      this.forms[id] = {
        ...this.release.changelogs.find(item => item.id === id),
        is_edit: !this.forms[id].is_edit
      }
    },
    async setReleases () {
      const data = await this.getReleases({
        page_size: 100
      })
      this.releasesList = data.results
    },
    setForms () {
      const result = {}
      this.release?.changelogs?.forEach(item => {
        result[item.id] = {
          ...item,
          is_edit: false
        }
      })
      this.forms = result
    },
    editDescription (id, data) {
      const { description, release } = data
      this.edit({ description, release }, id)
    },
    editActive (id, is_active) {
      this.edit({ is_active }, id)
    },
    async edit (body, id) {
      if (this.isLoad) return
      this.isLoad = true
      const release_id = this.$route.params.id
      await this.patchLog({ body, id, release_id })
      this.isLoad = false
      this.$emit('onUpdate')
    },
    showDeleteForm (id) {
      this.removeId = id
      this.isViewForm = true
    },
    onUpdate () {
      this.removeId = null
      this.isViewForm = false
      this.$emit('onUpdate')
    },
    closeForm () {
      this.isLoad = false
      this.isViewForm = false
    },
    clearForms () {
      // this.$refs.EditLog?.clearForm()
      // this.$refs.DiscountLog?.clearForm()
      // this.$refs.DeactivateLog?.clearForm()
      // this.$refs.CreateLog?.clearForm()
    }
  }
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/constants.sass'
.background-blue-800
  background-color: $blue-800 !important
.switch-column
  width: 80px
</style>