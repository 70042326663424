import api from '@/plugins/api'
import { payment } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'

// const store = 'payments'

export default {
  state: () => ({}),
  actions: {
    async getPayments (context, { id, page_size = 10, page = 1, mode = 'payment', ...params }) {
      const _params = Object.entries(params).map(([key, value]) => `&${ key }=${ value }`).join('')
      const response = await api.get(`${ payment.payments({
        id,
        mode
      }) }/?page_size=${ page_size }&page=${ page }${ _params }`)
      await requestValidation(response, { isViewNotify: true })
      return response.data
    },
    async downloadPayment (context, { account, id, mode = 'payment' }) {
      const response = await api.get(payment.download({ account, id, mode }), {
        requestType: 'pdf',
        responseType: 'blob'
      })
      await requestValidation(response, { isViewNotify: true })
      return response.data
    }
  },
  getters: {}
}
