import api from '@/plugins/api'
import { article as endpoint } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
import { getParams } from '@/mixins/main'

export default {
  actions: {
    async getTags () {
      const response = await api.get(endpoint.tag())
      if (await requestValidation(response)) {
        return response.data
      }
    },

    async postTag (context, body = {}) {
      const response = await api.post(endpoint.tag(), { body })
      if (await requestValidation(response, { isViewNotify: true, notifyTitle: 'notify.articleCreated' })) {
        return response.data
      }
    },

    async patchTag (context, { body, id }) {
      const response = await api.patch(endpoint.tag(id), { body })
      if (await requestValidation(response, { isViewNotify: true, notifyTitle: 'notify.articleUpdated' }))
        return response.data
    },

    async deleteTag (context, id) {
      const response = await api.delete(endpoint.tag(id))
      return await requestValidation(response, { isViewNotify: true, notifyTitle: 'notify.userDeleted' })
    },

    async getArticles (context, { page_size = 10, page = 1, ...params }) {
      const _params = getParams(params)
      const response = await api.get(`${ endpoint.article() }?page_size=${ page_size }&page=${ page }${ _params }`)
      if (await requestValidation(response)) {
        return response.data
      }
    },

    async getArticle (context, id) {
      const response = await api.get(endpoint.article(id))
      if (await requestValidation(response)) {
        return response.data
      }
    },

    async postArticle (context, body = {}) {
      const response = await api.post(endpoint.article(), { body })
      if (await requestValidation(response, { isViewNotify: true, notifyTitle: 'notify.articleCreated' })) {
        return response.data
      }
    },

    async patchArticle (context, { body, id }) {
      const response = await api.patch(endpoint.article(id), { body })
      if (await requestValidation(response, { isViewNotify: true, notifyTitle: 'notify.articleUpdated' }))
        return response.data
    },

    async deleteArticle (context, id) {
      const response = await api.delete(endpoint.article(id))
      return await requestValidation(response, { isViewNotify: true, notifyTitle: 'notify.articleDeleted' })
    },

    async uploadArticleFile (context, { id, body }) {
      const response = await api.post(endpoint.upload(id), { body })
      if (await requestValidation(response, { isViewNotify: false, notifyTitle: 'notify.fileUploaded' })) {
        return response.data
      }
    },

    async removeArticleFile (context, { id, fileId }) {
      const response = await api.delete(endpoint.remove(id, fileId))
      if (await requestValidation(response, { isViewNotify: false, notifyTitle: 'notify.fileRemoved' })) {
        return response.data
      }
    }
  }
}
