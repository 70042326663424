<template lang="pug">
  include ../../plugins/pug/template

  v-card(tag="form" @submit.prevent="send").bg-blue
    v-card-title.pa-8.pb-6.d-flex.flex-row.justify-space-between.align-center
      span.font30 {{ title }}
      v-btn(icon @click="$emit('close')")
        img(:src="CloseDark")
    v-card-text.mb-0.pb-0.px-8
      p.font16 {{ $t('deleteUserDescription', {user: user.full_name}) }}
    v-card-actions.d-flex.flex-column.justify-center.align-center.mx-8.pa-0.mb-8
      input(ref="form").hiddenInput
      +btn-general('btn.delete', 'row-right-white.svg', 'primary-gradient')(
        type="submit"
        :loading="isLoad"
        large
      ).mb-0.w-full

</template>

<script>
import CloseDark from '@/assets/icons/close-dark.svg'
import { mapActions } from 'vuex'
import { nextTick } from 'vue'

export default {
  name: 'FormChangePassword',
  props: {
    title: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isLoad: false,
    CloseDark
  }),
  mounted () {
    nextTick(() => {
      this.$refs.form.focus()
    })
  },
  methods: {
    ...mapActions(['deleteUser']),
    async send () {
      if (this.isLoad) return
      this.isLoad = true

      await this.deleteUser(this.user.id)
      this.isLoad = false
      this.$emit('close')
      this.$router.push({ name: 'accounts' })
    }
  }
}
</script>

<style lang="sass" scoped>
.hiddenInput
  position: absolute
  width: 0px
  height: 0px
</style>