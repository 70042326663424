const apiVersion = 'api/v1'
const apiAdmin = 'api/v1/admin_cabinet'

export const auth = {
  totp: {
    create: `${ apiVersion }/accounts/totp/create/`,
    verify: `${ apiVersion }/accounts/totp/verify/`,
    login: `${ apiVersion }/accounts/totp/login/`
  },
  u2f: {
    registration: {
      options: '',
      registration: ''
    },
    authorization: {
      options: `${ apiVersion }/accounts/fido2/begin/`,
      authorization: `${ apiVersion }/accounts/fido2/complete/`
    }
  },
  general: {
    login: `${ apiVersion }/accounts/login/`
  },
  phone: {
    login: ''
  }
}
export const directory = {
  tariff: `${ apiVersion }/directory/tariff/`,
  countries: `${ apiVersion }/directory/countries/`
}
export const admin = {
  licenses: id => `${ apiAdmin }/licenses/${ id ? id + '/' : '' }`,
  accounts: id => `${ apiAdmin }/accounts/${ id ? id + '/' : '' }`,
  changelogs: ({ id, release_id }) => `${ apiAdmin }/ep_releases/${ release_id }/changelogs/${ id ? id + '/' : '' }`,
  releases: id => `${ apiAdmin }/ep_releases/${ id ? id + '/' : '' }`,
  cash: id => `${ apiAdmin }/accounts/${ id }/cash_payment/`
}

export const profile = {
  me: `${ apiVersion }/accounts/me/`,
  license: `${ apiAdmin }/licenses/`,
  download: id => `${ apiAdmin }/licenses/${ id }/download_epv2/`
}

export const user = {
  userClient: `${ apiVersion }/accounts/`,
  user: `${ apiAdmin }/accounts/`,
  create: `${ apiAdmin }/accounts/registration/`
}
export const article = {
  tag: id => `${ apiAdmin }/tags/${ id ? id + '/' : '' }`,
  article: id => `${ apiAdmin }/articles/${ id ? id + '/' : '' }`,
  upload: id => `${ apiAdmin }/articles/${ id }/upload_file/`,
  remove: (id, fileId) => `${ apiAdmin }/articles/${ id }/remove_file/${ fileId }`
}
export const payment = {
  payments: ({ id, mode }) => `${ apiAdmin }/accounts/${ id }/${ mode }s`,
  download: ({ account, id, mode }) => `${ apiAdmin }/accounts/${ account }/${ mode }s/${ id }/download_${ mode }/`
}
