<template lang="pug">
  include ../../plugins/pug/template

  v-card(tag="form" @submit.prevent="send").bg-blue
    v-card-title.pa-8.pb-6.d-flex.flex-row.justify-space-between.align-center
      span.font30 {{ $t('diactivateUser') }}
      v-btn(icon @click="$emit('close')")
        img(:src="CloseDark")
    v-card-text.mb-0.pb-0.px-8
      +field-area('body.comment', 'comment', 4)
      +switch('body.is_banned', 'is_banned', 'is_bannedFalse', '').mt-0.pa-0
    v-card-actions.d-flex.flex-column.justify-center.align-center.mx-8.pa-0.mb-8
      +btn-general('btn.save', 'row-right-white.svg', 'primary-gradient')(type="submit" :loading="isLoad" large).mb-0.w-full

</template>

<script>
import CloseDark from '@/assets/icons/close-dark.svg'

export default {
  name: 'FormDeactivateUser',
  props: {
    isLoad: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    body: {
      comment: null,
      is_banned: false
    },
    CloseDark
  }),
  methods: {
    send () {
      if (this.isLoad) return
      const requestData = {
        ...this.body
      }
      this.$emit('send', requestData)
    },
    editForm (newBody) {
      this.clearForm()
      this.body = { ...newBody }
    },
    clearForm () {
      this.body = {
        comment: null,
        is_banned: false
      }
    }

  }
}
</script>
