export default {
  notify: {
    requiredField: 'Поле обязательное'
  },
  btn: {
    sign: 'Войти',
    detail: 'Подробно',
    save: 'Сохранить',
    add: 'Добавить',
    open: 'Открыть',
    delete: 'Удалить',
    cancel: 'Отменить',
    setCourse: 'Назначить курс',
    close: 'Закрыть',
    readKey: 'Считать ключ',
    signDocument: 'Подписать'
  },
  lang: {
    ukInstrumental: 'на украинском',
    enInstrumental: 'на английском',
    ruInstrumental: 'на русском',
    uk: 'Украинский',
    en: 'Английский',
    ru: 'Росийский'
  }
}
