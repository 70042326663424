<template lang="pug">
  include ../../../plugins/pug/template
  div
    div(v-if="!author").w-full.d-flex.flex-row
      +select-label(
        'serverStatus',
        'serverStatuses',
        'serverStatus',
        'name',
        'value',
        '',
        ''
      )(
        required
        @change="changePage"
      ).mr-1
      +field-label-validation({ model: 'search', label: 'author', validation: '', appendIcon: 'mdi-magnify' })(
        @change="changePage"
      ).mr-2
      +field-label-validation({ model: 'name', label: 'licence', validation: '', appendIcon: 'mdi-magnify' })(
        @change="changePage"
      ).mr-2
      +select-label(
        'tariff',
        'tariffs',
        'tariff',
        'name',
        'value',
        '',
        ''
      )(
        required
        @change="changePage"
      )
    Table(
      ref="table"
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :pageCount="pageCount"
      :changePage="changePage"
      :isLoad="isLoad"
      :noDataText="$t('NoAvailableData')"
      :btnLinkFunction="btnLinkFunction"
    )
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Table: () => import('@/components/layout/Table.vue')
  },
  name: 'TableLicense',
  props: {
    author: {
      type: String,
      default: ''
    },
    itemsPerPage: {
      type: Number,
      default: 20
    }
  },
  data () {
    return {
      tariff: 'All',
      serverStatus: 'All',
      name: '',
      search: '',
      filter: {},
      currentPage: this.$route.query.page || 1,
      pageCount: 0,
      isLoad: true,
      items: [],
      headers: [
        {
          text: this.$t('name'),
          value: 'name',
          align: 'center'
        },
        {
          text: this.$t('tariff'),
          value: 'tariff.name',
          sortBy: 'tariff',
          align: 'center'
        },
        {
          text: this.$t('status'),
          value: 'is_banned',
          align: 'center'
        },
        {
          text: this.$t('serverStatus'),
          value: 'server_status',
          align: 'center'
        },
        {
          text: this.$t('NextPayment'),
          value: 'next_payment',
          align: 'center'
        },
        {
          text: this.$t('countUsedOnu'),
          value: 'count_used_onus',
          sortBy: 'used_onus',
          align: 'center'
        },
        {
          text: this.$t('view'),
          value: 'itemLink',
          sortable: false,
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      tariffsStore: state => state.directory.tariffs?.results || []
    }),
    tariffs () {
      return [
        {
          name: 'All',
          value: 'All'
        },
        ...this.tariffsStore.map(item => ({
          name: item.name,
          value: item.name
        }))]
    },
    serverStatuses () {
      return Object.keys(this.$t('serverStatuses')).map((key) => ({
        value: key, name: this.$t('serverStatuses')[key]
      }))
    }
  },
  mounted () {
    this.changePage()
  },
  methods: {
    ...mapActions(['getAdminLicenses']),
    btnLinkFunction (item) {
      return item.author?.id ? { name: 'accounts-info', params: { id: item.author.id } } : null
    },
    async changePage () {

      this.isLoad = true
      const params = {
        ...this.$route.query,
        page_size: this.itemsPerPage,
        page: this.$route.query.page || this.currentPage || 1,
        ...this.filter
      }
      if (this.serverStatus !== 'All' && this.serverStatus) {
        params.server_status = this.serverStatus
      }
      if (this.tariff !== 'All' && this.tariff) {
        params.tariff_name = this.tariff
      }

      if (this.name) {
        params.name = this.name
      }

      if (this.search) {
        params.author = this.search
      } else if (this.author) {
        params.author = this.author
      }
      const data = await this.getAdminLicenses(params)
      if (!data?.results) return
      this.items = data.results
      this.pageCount = Math.ceil(data.count / this.itemsPerPage)
      this.isLoad = false
    }
  }
}
</script>

