export default {
  install (Vue) {
    Vue.prototype.$number = (raw, min = 0, max = 2) => {
      try {
        raw = parseFloat(raw)
        const result = raw.toLocaleString('uk-UA', {
          minimumFractionDigits: min || 2,
          maximumFractionDigits: max || 8
        }).replace(',', '.')
        if (min === 0)
          return result.replace('.00', '')
        return result

      } catch (error) {
        return '-'
      }
    }
  }
}