import { decimal, email, maxValue, required } from 'vuelidate/lib/validators' // alphaNum
// const regexAlphaNumeric = helpers.regex('alpha', /^[A-Za-z0-9'\-. ]*$/)

export const login = {
  email: { required, email },
  password: { required }
}
export const cash = {
  amount: { required, decimal }
}

export const server = {
  name: { required }
}
export const discount = {
  discount_amount: { required, maxValue: maxValue(100) },
  discount_active_to: {
    minDateValue: (val) => {
      const target = new Date(val).setHours(0, 0, 0, 0)
      const now = new Date().setHours(0, 0, 0, 0)
      return val ? target >= now : true
    }
  }
}
